import React, { useEffect, useState, forwardRef, createRef, Fragment } from 'react';
// import View from '../View.js'
import Draggable from 'react-draggable';
// import FilterBar from '../../ui/FilterBar.js';
import { observer } from 'mobx-react-lite'
import { View_ } from '../View'
import ActionWidget from './ActionWidget'
import SupersetDashboard from './SupersetDashboard';
import GridHoc from '../../ui/GridHoc.js'
import ViewHeader from '../ViewHeader'
import ViewEditionToolbar from '../ViewEditionToolbar.js';
import QuickFiltersArea from '../../ui/filters/QuickFiltersArea.js';
import { ReactComponent as EditPencil } from '../../ui/icons/svg/edit-pencil.svg';
import IconButton from '../../ui/buttons/IconButton.js';


// const widgets_map = {
//     'view': ({props}) => <View_  screen={props.widget.screen} container="paper"  hide_toolbar={false} {...props}/>,
//     'action': (props) => <ActionWidget {...props}/>,
//     'superset_dashboard':  (props) => <SupersetDashboard target="board_base" {...props}/>

// }

const widgets_map = {
    'view': (props) => <div className='overflow-auto max-h-full'>
        <View_ screen={props.widget.screen} 
                container="paper" 
                hide_toolbar={false}  
                {...props} 
                height={(props.height - 30) * 0.85} /></div>,
    'action': (props) => <ActionWidget {...props} />,
    'superset_dashboard': (props) => <SupersetDashboard target="board_base" {...props} height={(props.height) * 0.90} />

}


const WidgetContainer = observer(forwardRef(function WidgetContainer(props, ref) {
    const { widget } = props
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)

    useEffect(() => {
        if (!ref.current) return; // wait for the elementRef to be available
        const resizeObserver = new ResizeObserver(() => {
            // const new_height = (ref.current.getBoundingClientRect().height-30)*0.85
            const new_height = ref.current.getBoundingClientRect().height
            setHeight(new_height)
            setWidth(ref.current.getBoundingClientRect().width)

        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, []);

    return (
        <div
            ref={ref}
            {...props}
            key={widget.id}
            onContextMenu={function (e) {
                e.preventDefault()
                widget.open_context_menu(e.currentTarget)
                e.stopPropagation()
            }}
        // className={"bg-red-300"}
        >

            {widgets_map[widget.type]({ widget: widget, height: height, width: width })}
            {props.children}




        </div>
    )
}));



export const NewBoardView = observer(function NewBoardView(props) {
    const { screen } = props
    const widgets = screen.widgets
    const widgets_loaded = screen.widgets_loaded
    const container_ref = React.createRef()
    const editor_options = screen.editor.edition_options
    const edition_mode = screen.editor ? screen.editor.design_mode : false

    return (
        <div id="board_base" ref={container_ref}>
            {editor_options.length ?
                <>
                    <ViewEditionToolbar
                        screen={screen}
                        container_classname='mb-2 sticky top-0'
                    />
                    <ViewHeader
                        screen={screen}
                        title_classname="text-xl px-6 py-1 text-gray-800"
                        hide_toolbar={props.hide_toolbar}
                        isSpreadsheet={false}
                    />

                </>
                :
                ""

            }
            <QuickFiltersArea
                screen={screen}
            />



            {widgets.length ?
                <GridHoc screen={screen} rowHeight={20}>
                    {widgets.map((widget, index) => (
                        <WidgetContainer
                            key={widget.id}
                            widget={widget}
                            edition_mode={edition_mode}
                            ref={createRef()}
                        />


                    ))}
                </GridHoc>
                :
                <>
                    {widgets_loaded &&
                        <div className="text-base text-gray-600 px-4 font-medium flex flex-row items-center">

                            Your board is empty. Press the edit button
                            <IconButton onClick={(e) => { screen.editor.toogle_design_mode() }} color={'transparent'}>
                                <EditPencil 
                                    style={{ width: '.8em', height: '.8em' }} 
                                    className="fill-current text-xl cursor-pointer w-4 h-2"
                                    />
                            </IconButton>
                             in the toolbar and add your first widget


                        </div>
                    }
                </>


            }


        </div>


    )

}
)

export default NewBoardView;
