import React, { useReducer, useEffect } from 'react';
import { sheet_navigation_signals } from '../../common/common.js';
import moment from 'moment';
import CalendarPicker from './CalendarPicker'
//import 'react-calendar/dist/Calendar.css';
import IconButton from '../buttons/IconButton'
import { ReactComponent as CalendarIcon } from '../icons/svg/calendar.svg';
import InputMask from 'react-input-mask';


const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-600  placeholder-opacity-60 focus:outline-none rounded-lg w-full appearance-none"



const initialState = {
    'calendar_open': false,
    'calendar_value': "",
    'input_value':"",
    'value': "",
    'changed':false
}



function DateField(props) {
    const calendar_ref = React.createRef();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {state_attrs, field, record} = props
    const minDate = field.get_min_value(record)
    const extra_props = {
        'locale':field.locale.lang
    }
    if(minDate){
        extra_props['minDate'] = minDate
    }
    

    function reducer(state, action) {

        switch (action.type) {
            case 'SET_VALUE':
                let is_valid = moment.isMoment(action.payload.value) && action.payload.value._isValid 
                const calendar_value = is_valid ? moment(action.payload.value).toDate() : moment().toDate()
                const value = is_valid ? action.payload.value : ""
                // const input_value = is_valid ? common.formatDate(action.payload.value, props.field.type):""
                const input_value = is_valid ? props.field.format_date(action.payload.value):""
                return { ...state, calendar_value: calendar_value, value: value, input_value:input_value, changed:false }
            case 'SET_INPUT_VALUE':
                return {...state, input_value:action.payload.value, changed:true}
            case 'SET_CALENDAR_OPEN':
                const calopen = !state.calendar_open
                const changed = action.payload.changed ? action.payload.changed:false
                return { ...state, calendar_open: calopen, changed:changed }
            default:
                throw new Error("Invalid Action".concat(action.type))

        }
    }

    useEffect(() => {
        
        dispatch({ type: 'SET_VALUE', payload: {value:props.value} })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);



    function setValue(value) {
        console.log("RAW VALUE SENT")
        console.log(value)
        let val = value ? field.format_value(value):null
        console.log("FORMATTED VALUE")
        console.log(val)
        props.onChange("", val)
        props.onBlur("", val)
        dispatch({ type: 'SET_CALENDAR_OPEN', payload: {changed:true} })
    }
    function onChangeInput(e){
        dispatch({ type: 'SET_INPUT_VALUE', payload: {value:e.target.value} })
    }
    

    function toogleCalendar() {
        // setCalendarOpen(!calendar_open)
        dispatch({ type: 'SET_CALENDAR_OPEN', payload: {} })
    }

    function onBlurInput(e){
        if(state.changed){
            setValue(e.target.value)
           
        }
        
    }
    function handleKeyDown(e){
        if(state.changed){
            if(sheet_navigation_signals.includes(e.key)){
                setValue(e.target.value)
            }
        }
        
        
    }


    return (
        <div className="flex items-center">
            <InputMask 
                id={field.id || field.name} 
                autoFocus={props.autoFocus}
                mask={field.mask}
                value={state.input_value}
                onChange={onChangeInput}
                onBlur={onBlurInput}
                alwaysShowMask
                required={state_attrs.required}
                readOnly={state_attrs.readonly}
                placeholder={field.description}
                style={props.style}
                onKeyDown={handleKeyDown}
                name={field.name}
                className={base_cls.concat(
                    !state.input_value ? " font-normal text-gray-500 " : " "
                    ).concat(props.className)}
                
                />
            {!state_attrs.readonly &&
            <div style={{maxHeight:'1rem' }} ref={calendar_ref}>
        
                <IconButton style={{padding:0}} onClick={(e) => { toogleCalendar() }} color="transparent" className={props.classNamePrefix === "field-filter" ? "-ml-7 mt-2.5": "mt-1"}>
                <CalendarIcon style={{ width: '.8em', height: '1rem' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
            </IconButton>
               
                
            </div>
             }
      <CalendarPicker
        open={state.calendar_open}
        value={state.calendar_value}
        onClose={() => { return true }}
        calendar_ref={calendar_ref}
        onChange={setValue}
        // minDate={minDate ? minDate:false}
        modifiers={{
                left: -218,
                top: 12,
                width: 220,
        }}
        extra_props={extra_props}


      />
    </div>




  )

}

export default DateField;