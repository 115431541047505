const field_align = {
    'button':'center',
    'float':'right',
    'integer':'right'
    
}

export const get_field_align = (type) =>{
    if(field_align[type]){
        return field_align[type]
    }
    return 'left'
}