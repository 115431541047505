import { action, makeObservable, observable, computed } from "mobx"
import { ScreenGroup } from '../screen/ScreenGroup'


class MenuEntry{
    level = 0
    childs = []
    name = ''
    bg_color = ''
    text_color = ''

    get custom_style(){
        let custom_style = {}
    
        if(this.bg_color){
            custom_style['backgroundColor'] = this.bg_color
        }
        if(this.text_color){
            custom_style['color'] = this.text_color
        }
        return custom_style
    }

    constructor(attributes, menu, level=0, parent=false) {
        this.attributes = attributes
        this.menu = menu
        this.level = level
        this.parent = parent
        this.name = attributes.name
        this.bg_color = attributes.bg_color
        this.text_color = attributes.text_color
        this.expanded = false
        makeObservable(this,{
            level:observable,
            childs:observable,
            expanded:observable,
            set_expanded:action,
            select:action,
            set_childs:action,
            custom_style:computed
        })     
        
    }
    set_childs(){
        this.childs = this.get_childs()
    }
    get_childs(){
    }

    select(){
    }
    set_expanded(expanded){
        this.expanded = expanded
    }

    cascade_close(){
        if(this.parent && this.parent.expanded){
            this.parent.cascade_close()
            this.parent.set_expanded(false)
        }
        
    }
}

class ViewMenuEntry extends MenuEntry{
    type = 'view'
    active = false

    get route(){
        return this.menu.connection.getRouteById(this.view_id)
    }
    constructor(attributes, menu, level=0, parent=false) {
        super(attributes, menu, level, parent)
        this.view_id = attributes
        // this.name = attributes.name
        this.record = attributes.record
        this.name = this.record ? this.record.get_value(this.parent.child_records_rec_name):attributes.name
        this.active = false
        makeObservable(this,{
            view_id:observable,
            active:observable,
            set_active:action
            
        })     
        
    }
    set_active(value){
        this.active = value 
    }

    select(callback=false){
        if(this.parent && this.parent.navigate_action){
            this.parent.open_record(this.record)
            
        }
        this.menu.set_active_entry(this)
        if(callback){
            callback()
        }
    }
}

class RecordCategoryMenuEntry extends MenuEntry{
    type = 'child_records'
    child_records_action = false
    child_records_view = false
    child_records_rec_name = ''
    screen = false
    get navigate_action(){
        return this.screen.get_action_by_id(this.child_records_action.id)
    }
    constructor(attributes, menu, level=0, parent=false) {
        super(attributes, menu, level, parent)
        this.child_records_action = attributes.child_records_action
        this.child_records_rec_name = attributes.child_records_rec_name || 'name'
        this.screen = this.menu.group.addScreen(attributes.child_records_view, (path)=>{this.menu.navigate(path)}, [], {}, false, false, false, true, true, true, () => this.set_childs(), false, false)
        this.name = attributes.name
        
    }
    set_childs(){
        this.childs = this.get_childs()
    }
    get_childs(){
        return this.screen.data.records.map(function(record){
            const vals = {...record._values, record:record}
            return createEntry(vals, this.menu, parent=this, this.level+1)
        }.bind(this))
        
    }
    select(){        
        this.set_expanded(!this.expanded)
        this.set_childs()
    }
    open_record(record){
        this.navigate_action.execute([record])
    }
}

class CategoryMenuEntry extends MenuEntry{
    type = 'category'

    get route(){
        return this.menu.connection.getRouteById(this.attributes.view_id)
    }

    select(){    
        this.set_expanded(!this.expanded)
        this.set_childs()
    }

    get_childs(){
        return this.attributes.childs.map(function(child){
            return createEntry(child, this.menu, parent=this, this.level+1)
        }.bind(this))
    }

}

export const createEntry = (attributes, menu, parent=false, level=0) => {
    if(Number.isInteger(attributes) || !attributes.hasOwnProperty('type')){
        return new ViewMenuEntry(attributes, menu, level, parent)
    }
    let entry;
    if(attributes.type == 'category'){
        entry = new CategoryMenuEntry(attributes, menu, level, parent)
    }
    else if (attributes.type == 'child_records'){
        entry =  new RecordCategoryMenuEntry(attributes, menu, level, parent)
    }
    else{
        throw new Error("Unsupported Menu Type: " + attributes.type)
    }

    // switch (attributes.type) {
    //     case 'category': 
    //     case 'child_records': 
    //     default: 
    // }
    entry.set_childs()
    return entry
}


export class Menu {
    get roots(){
        return this.tree.filter(entry => entry.level === 0)
    }
    get expanded_roots(){
        return this.roots.filter(entry => entry.expanded)
    }

    constructor(connection, tree=[], routes=[], ) {
        this.raw_tree = tree 
        this.raw_routes = routes
        this.connection = connection
        this.group = new ScreenGroup(this.connection, true)
        this.tree = []
        this.navigate = ()=>{console.log("Menu navigation function not defined")}
        this.active_entry = false
        makeObservable(this,{
            raw_tree:observable,
            raw_routes:observable,
            group:observable,
            navigate:observable,
            active_entry:observable,
            set_active_entry:action,
            set_navigation:action
        })     
        
        
    }

    setup_tree(routes, tree){
        // this.raw_tree = tree
        this.raw_routes = routes
        this.tree = tree.map(function (attributes) {
            return createEntry(attributes, this)
        }.bind(this))
        console.log("THE setup tree")
        console.log(tree)
    }

    set_navigation(nav_func){
        console.log("SETTING NAVIGATION ON MENU")
        console.log(nav_func)
        this.navigate = nav_func
    }
    set_active_entry(active_entry){
        if(this.active_entry){
            this.active_entry.set_active(false)
        }
        
        this.active_entry = active_entry
        this.active_entry.set_active(true)
        
    }

    collapse_all(){
        this.tree.forEach(function(entry){
            if(entry.expanded){
                entry.set_expanded(false)
            }
            
        })
    }
    toogle_root(item){
        this.expanded_roots.forEach(function(entry){
            if(item != entry && !entry.childs.includes(item)){
                entry.set_expanded(false)
            }
        })
        
    }





}