import React, { useContext, useEffect, useRef } from 'react';
import AppBar from './AppBar.js'
import SideBarMenu from './SideBarMenu.js'
import {Paper, DropDown, Screens, LoadingIndicator} from 'kalenis-web-components'
import { ReactComponent as AccountIcon } from '../icons/user-solid-circle.svg';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';

import { SessionContext } from 'kalenis-portal-frontend-main'
import {observer} from 'mobx-react-lite'
import UserContextView from '../auth/UserContextView.js';
import CustomLoader from '../views/CustomLoader.js';
import {

    useHistory

} from "react-router-dom";


const Header = observer(function Header(props){
    const session = useContext(SessionContext)
    // const app_context = useContext(AppContext)
    // const filterable = app_context.active_screen.filterable
    
    const [account_menu, setAccountMenu] = React.useState(false);
    const header_ref = React.createRef();


    const toogleAccountMenu = () => {
        
        setAccountMenu(!account_menu);
    };


    return (
        <AppBar menuWidth={props.menuWidth}>
            {account_menu &&
                <DropDown
                    ref={header_ref}
                    open={account_menu}
                    modifiers={{
                        width:300,
                        left:-310
                    }}
                    closeOnClickOutside={true}
                    onClose={toogleAccountMenu}
                    >
                        <Paper shadowClass="shadow-md">
                            <UserContextView/>
                        </Paper>
                </DropDown>
            }
            
            
            
            
            <div style={{minHeight:'64px'}} className="flex items-center justify-between pl-4 pr-2 md:pl-6 md:pr-0">
            
                
                    
                    
                    {props.menuWidth === 0 ?
                    <MenuIcon style={{ width: '1em', height: '1em' }} onClick={(e)=>props.setMenuWidth(240)} className="fill-current text-contrast-primary text-2xl xs:hidden cursor-pointer w-4 h-4 mr-6" />
                    :
                    <MenuIcon style={{ width: '1em', height: '1em' }} onClick={props.setMobileOpen} className="fill-current text-contrast-primary text-2xl md:hidden cursor-pointer w-4 h-4 mr-6" />        
                    }
                    

                <div className="flex flex-row flex-grow">
                <div onClick={toogleAccountMenu} className="w-1/4 flex-grow text-xs font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap text-right px-2 cursor-pointer">
                    {(session.user_context && session.user_context.initialized) && 
                        <>{session.user_context.appbar_data || ""}</>
                    }
                    {/* {
                        session.is_sync_active ?
                        <div className="text-sm font-medium px-2">[ Syncing.... ]</div>
                        :
                        <div onClick={() => session.reSync()} className="text-sm font-medium px-2">[ Synced ]</div>
                    } */}
                    


                </div>
                <AccountIcon ref={header_ref} style={{ width: '1em', height: '1em' }} onClick={toogleAccountMenu} className="fill-current text-contrast-primary text-2xl cursor-pointer w-4 h-4 mr-1 md:mr-4 ml-2" />
                </div>
            </div>
        </AppBar>
    )

});

const DefaultMain = observer(function Main(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [menuWidth, setMenuWidth] = React.useState(240);
    
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawerClass =  mobileOpen ? " fixed z-40  p-0 border border-gray-300 ":" fixed z-40 border border-gray-300 hidden md:flex p-0 "

    const [calcHeight, setCalcHeight] = React.useState(0);
  
    // change height size effect
    useEffect(() => {
        if (window.innerHeight !== calcHeight) {
            setCalcHeight(window.innerHeight - 150);
        }
    }, [window.innerHeight]);

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [menuWidth]);

    function IsScreen(query){
        if(Screens){
            return Screens.IsScreen(query)
        }
        return false
    }

    return (
        <>
            <div className="flex">
                <Header menuWidth={menuWidth} setMenuWidth={setMenuWidth} setMobileOpen={handleDrawerToggle} />
                <Paper shadowClass="shadow-md" style={{ padding: 0, width: mobileOpen ? 240:menuWidth, display:!menuWidth ? 'none':'' }} className={drawerClass}>
                    <nav className="h-screen w-full z-50 left-0 top-0 bg-white">
                        <SideBarMenu setMenuWidth={setMenuWidth}  setMobileOpen={handleDrawerToggle} calcHeight={calcHeight}/>

                   
                    </nav>
                </Paper>
                <main style={{ marginLeft: IsScreen({type:'sm',down:true}) ? '0px':menuWidth }} className="flex-grow max-w-full right-0 sm:p-2 mt-16">
                
                    {props.children}
                </main>

            </div>
        </>
        
    )
    
})

const Main = observer(function Main(props) {
    
    const history = useHistory();
    const connection = useContext(SessionContext)
    const session_status = connection.status
    const custom_home_view = connection.custom_home_view
    
    useEffect(() => {
        if (!session_status) {
            history.replace('/login')
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session_status]);

    
    
    return (
        <>
        {custom_home_view ?
            <CustomLoader component_name={custom_home_view} {...props}/>
            // <div className="flex">TEST</div>
            :
            <DefaultMain {...props}/>
        }
        <LoadingIndicator
                    ref={React.createRef()}
                    show={connection.loading}
                    fullscreen

                />
        </>
        
    )
    
})



export default Main;