import { makeAutoObservable, computed, action, runInAction, observable, makeObservable, toJS } from "mobx"
import { createField } from '../model/Field'
import { Action } from '../model/Action'
import { RecordGroup, Record } from '../model/Record'
import { AttachmentsHandler } from "../model/Attachment"
import { Filters } from '../model/Filters'
import {ScreenEditor} from './ScreenEditor'
import {grid_layout_defaults} from "../ui/ui_defaults"
import { domain_to_object,environment  } from '../common'
import { ScreenLayout } from "./ScreenLayout"

const GENERAL_TYPE_ACTIONS = ['export_data']


export class Screen {
    view
    id
    id_field
    type
    fields = []
    actions = []
    records = []
    current_search = []
    order = []
    limit = null
    // pagination_enabled = false
    // default_page_size = 0
    // page_size_options = []
    processing = false
    loadingFields = true
    loadingInitialData = true
    loadingData = false
    headless = false
    action_id = ""
    action_params = []
    active_record = false
    filters = []
    parent = false
    field_childs = {}
    modal_childs = {}
    modal = false
    parent = false
    data = {}
    actions_map = {}
    mobile_record_title = ""
    mobile_record_header_image = ""
    auto_save = true
    start_empty = false
    record_style = ''
    style_modifiers = {
        'styles': {},
        'classnames': {}
    }
    view_template = ""
    default_filters = []
    default_filters_open = false
    screen_editor_access = {}
    fileHandler = false
    no_data_message = ""
    add_record_button = false
    readonly = false
    model = ""
    offline_available = false
    parent_ref = false
    default_columns = 4
    view_layout = ''
    use_view_layout = false
    group_by = []
    context = {}
    

    get locale(){
        return this.connection.user_context.locale
    }

    get root_screen(){
        if(this.parent){
            return this.parent.root_screen
        }
        else{
            return this
        }
    }

    // If parent screen is a field (example: o2m), use it as root
    get first_root_screen(){
        if(this.field_instance){
            return this
        }
        else if(this.parent){
            return this.parent.first_root_screen
        }
        else{
            return this
        }
    }

    get search_context(){
        return {...domain_to_object(this.current_search),...domain_to_object(this.action_params)}
        
    }

    get visible_fields() {
        const vf = this.fields.filter(field => field.visible)
        //needs review
        if (vf[0] !== "selector" && (this.type === 'list' && this.list_type !== 'card_list') || this.type === 'spreadsheet') {
            vf.unshift("selector")
        }
        
        return vf
    }
    get filterable_fields() {
        const vf = this.fields.filter(field => field.searchable)
        //needs review

        return vf
    }
    get conditional_style_fields() {
        const fields = this.fields.filter(field => field.conditional_style)
        

        return fields
    }
    get template_fields(){
        return this.fields.filter(function (f) {
            return f.include_in_template
        })
    }
    get record_dependant_fields(){
        return this.fields.filter(function (f) {
            return ['dynamic_one2many'].includes(f.type)
        })
    }
    get field_names() {
        return this.fields.map(function (f) { return f.name })
    }
    get initialized() {
        return !this.loadingFields && !this.data.loadingInitialData
    }
    get visible_actions(){
        return this.actions.filter(function (act) { return act.invisible == false })
        
    }
    get link_actions() {

        return this.visible_actions.filter(function (act) { return act.type === 'link' })
    }
    get report_actions() {

        return this.visible_actions.filter(function (act) { return act.type === 'report' })
    }
    get action_actions() {

        return this.visible_actions.filter(function (act) { return act.type === 'action' })
    }
    get view_group_actions() {

        return this.visible_actions.filter(function (act) { return act.type === 'view_group' })
    }
    get button_actions() {
        return this.actions.filter(function (act) { return act.show_button === true })
    }
    get visible_button_actions(){
        return this.visible_actions.filter(function (act) { return act.show_button === true })
    }
    get all_menu_actions() {
        return this.visible_actions.filter(function (act) { return act.show_button === false })
    }
    get default_action() {
        return this.actions.find(a => a.default === true)
    }
    get default_save_action() {
        return this.actions.find(a => a.default_save === true)
    }
    get delete_action() {
        const delete_actions = this.actions.filter(function (act) { return act.type === 'delete_record' })
        if (delete_actions.length === 1) {
            return delete_actions[0]
        }
        else if (delete_actions.length > 1) {
            throw new Error("Only one delete action its allowed")
        }
        else {
            return false
        }

    }
    get attachment_list_action(){
        return this.actions.find(a => ['portal_attachments_view_list'].includes(a.target_view_key))
    }
    get notes_list_action(){
        return this.actions.find(a => ['portal_notes_view_list'].includes(a.target_view_key))
    }
    get new_note_action(){
        return this.actions.find(a => ['portal_notes_view_form'].includes(a.target_view_key))
    }
    get new_attachment_action(){
        return this.actions.find(a => ['portal_attachments_view_form'].includes(a.target_view_key))
    }
    get attachments_enabled(){
        return (this.attachment_list_action || this.new_attachment_action)
    }
    get notes_enabled(){
        return (this.notes_list_action || this.new_note_action)
    }
    get metadata_enabled(){
        return (this.attachments_enabled || this.notes_enabled)
    }
    get general_actions() {

        return this.actions.filter(function (act) { return GENERAL_TYPE_ACTIONS.includes(act.type) })
    }

    get search_state() {
        return {
            current_search: this.current_search,
            action_params: this.action_params,
            action_id: this.action_id,
            order: this.order,
            group_by:this.group_by,
        }
    }

    get editable_search_state(){
        return {
            current_search: toJS(this.current_search),
            action_params: toJS(this.action_params),
            action_id: toJS(this.action_id),
            order: toJS(this.order),
            group_by:toJS(this.group_by),
        }
    }

    get notifications() {
        return this.connection.notifications
    }
    get mobile_primary_fields() {
        return this.visible_fields.filter(function (f) { return f.mobile_available === 'primary' })
    }
    get mobile_secondary_fields() {
        return this.visible_fields.filter(function (f) { return f.mobile_available === 'secondary' })
    }
    get mobile_record_image_header_field() {
        return this.fields.find(f => f.name === this.mobile_record_header_image)
    }
    get parent_selected_records(){
        if(!this.parent || !this.parent.selected_records){
            return []
        }
        return this.parent.selected_records

    }
    get editable_view(){
        return this.connection.user_context.view_editor
    }
    get currently_visible_fields(){
        const record = this.active_record
        if(!record){
            return this.visible_fields
        }
        return this.visible_fields.filter(field => field.is_visible(record))
    }

    constructor(group, 
                view, 
                connection, 
                navigate, 
                initial_search, 
                route_state, 
                is_modal, 
                parent, 
                initialize_fields = true, 
                initialize_actions = true, 
                initialize_data=true, 
                initialize_callback=false, 
                fileHandler=false,
                data_callback=false,
                on_load_data=false,
                context={},
                ) {

        this.connection = connection
        this.group = group
        this.initialize_data = initialize_data
        this.initialize_fields = initialize_fields
        this.initialize_actions = initialize_actions
        this.ordered_column = { name: false, order: false }
        this.navigate = navigate
        this.data_callback = data_callback
        this.on_load_data = on_load_data
        
        this.context = context
        // parent_ref: used for dropdown views, should be an object containing: cellRef (ref), posX(optional), posY(optional)
        this.parent_ref = view.parent_ref || false
        
        
        if(group && this.group.createGroup){
            this.field_childs = group.createGroup(connection, true, this.group.context_menu_handler)
            // this.modal_childs = new ScreenGroup(connection)
            this.modal_childs = group.createGroup(connection, true)
        }
        else{
            this.field_childs = []
            this.modal_childs = []
        }
        this.sub_screens = {}
        
        this.modal = is_modal
        this.parent = parent
        this.initialize_callback = initialize_callback
        this.editor = new ScreenEditor(this.connection.editor_config, this, view.view_edition_access)
        this.attachment_handler = new AttachmentsHandler(this)
        this.fileHandler = fileHandler
        this.readonly = view.readonly || false
        this.processing = false

        this.setup(view, initial_search, route_state)

        makeObservable(this, {
            //computed
            visible_fields: computed,
            initialized: computed,
            link_actions: computed,
            filterable_fields: computed,
            search_state: computed,
            //observables
            fields: observable,
            actions: observable,
            current_search: observable,
            action_id: observable,
            action_params: observable,
            active_record: observable,
            ordered_column:observable,
            filters: observable,
            order: observable,
            group_by:observable,
            data: observable,
            modal: observable,
            loadingFields: observable,
            style_modifiers: observable,
            title:observable,
            editor:observable,
            fileHandler:observable,
            add_record_button:observable,
            readonly:observable,
            context:observable,
            //actions
            new_record: action,
            set_title: action,
            set_actions_map: action,
            set_limit: action,
            add_classname: action,
            add_style: action,
            set_ordered_column:action,
            set_active_record:action,
            reset_fields:action,
            set_file_handler:action,
            set_add_record_button:action,
            set_readonly:action,
            processing:observable,
            set_processing:action,
            setup:action,
            extend_fields:action,
            view_layout:observable,
            use_view_layout:observable,
            set_context:action,
            currently_visible_fields:computed
            // readonly:observable,
            // set_readonly:action


        })


        // this.loadFields()
        // this.filters = view.filterable ? new Filters({}, this) : false
        // this.initialize(initial_search)
        
        // this.do_search(initial_search)
        // this.loadActions()
        

    }

    setup(view, search, route_state, type=false){
        this.view = view
        this.title = (route_state && route_state.title) ? route_state.title : view.title
        this.id = view.id
        this.model = view.model
        this.type = type ? type:view.type
        this.filterable = view.filterable
        this.id_field = view.id_field ? view.id_field : "id"
        this.data_origin = view.data_origin
        this.no_data_message = view.no_data_message
        this.mobile_record_title = view.mobile_record_title
        this.mobile_record_header_image = view.mobile_record_header_image
        this.start_empty = view.start_empty
        this.view_template = view.view_template
        this.default_filters = view.default_filters || []
        this.default_filters_open = view.default_filters_open
        this.offline_available = view.offline_available
        this.default_columns = view.default_columns ? view.default_columns:4
        this.use_view_layout = view.use_view_layout
        this.group_by = []
        this.fields_cache = !view.multi_model_view && !view.pivot_table
        this.record_style = view.record_style || ''
        if(this.group_by && search != false &&  !search.group_by){
            search.group_by = this.group_by
        }
        this.data = new RecordGroup(
            this.connection,
            {
                'pagination_enabled': view.pagination_enabled,
                'default_page_size': view.default_page_size,
                'page_size_options': view.page_size_options,
                'show_records_count': view.show_records_count
            },
            this)
        if(view.templates_category){
            this.templates_category = view.templates_category
        }
        this.initialize(search, view.view_layout)
        this.filters = view.filterable ? new Filters({'view_layout':view.filters_layout}, this) : false
        this.loadActions(false)
    }

    async initialize(search, view_layout) {
        await this.loadFields(search)

        if(this.use_view_layout){
            // if(!view_layout){
            //     view_layout=this.view.view_layout
            // }
            this.view_layout = new ScreenLayout(this, view_layout)
        }

        // If the screen starts empty, but there are search args in place, retrieve data
        let do_search = !this.start_empty
        if(search && search.current_search && search.current_search.length){
            do_search = true
        }
        if (this.initialize_data && do_search) {
            
            
            await this.do_search(search)
            
           
            
        }
        else {
            this.data.setInitialLoading(false)
            this.data.setLoading(false)
            if (this.filterable) {
                
                this.filters.setInitialFilters()
            }



        }
        // if(this.filterable){
        //     this.filters.setDefaultFilters()
        // }
        if(this.initialize_callback){
            this.initialize_callback(this)
        }


    }

    async loadDefinition(){
        const abortController = new AbortController();
        let args = { view_id: this.id }
        let new_definition = await this.connection.dispatch('GET', '/view/definition', args, false, false, false, abortController)
        return new_definition

    }

    async reinitialize(){
        // get fresh definition
        let new_definition = await this.loadDefinition()
        this.setup(new_definition, this.search_state, {})
    }

    set_file_handler(value){
        this.fileHandler = value;
    }
    async reload_field_childs() {
        if (this.active_record.id > 0) {
            this.data.o2m_fields.forEach(function (field) {
                if(field.initialized){
                    field.reload(this.active_record)
                }
                else{
                    field.initialize(this.active_record, true)
                }
                
            }.bind(this))
        }

    }
    reinitialize_field_childs(){
        const childs = this.fields.filter(function (field) { return field.type == 'one2many' })
        childs.forEach(function(field){
            field.initialize(this.active_record, true)
        }.bind(this))
    }

    async reload_root_screen(){
        if(this.parent){
            this.parent.reload_root_screen()
        }
        else{
            await this.do_search()
            await this.reload_field_childs()
        }
    }

    async reload_parent() {
        if(this.parent){
            await this.parent.do_search(this.parent.search_state)
            await this.parent.reload_field_childs()
        }
        
    }
    //value: {text,color,execute:()=>{}}
    set_add_record_button(value){
        this.add_record_button = value
    }
    
    //value: function to determinate if the screen is readonly
    set_readonly(value){
        runInAction(() => {
            this.readonly = value;
            this.data.records.forEach(function(record){
                record.set_state_attrs()
            })


        })
        
    }
    // value in [true, false]
    set_processing(value){
        this.processing = value
    }

    async do_search(search, force) {
        let count = false
        let reset_page = false

        if (search) {
            // this.search = search
            this.current_search = search.current_search || this.current_search
            this.action_params = search.action_params || this.action_params
            this.action_id = search.action_id || false
            this.order = search.order || this.order
            this.group_by = search.group_by || this.group_by
            //count records and reset page in every search change
            count = true
            reset_page = true
        }
        if (this.filterable) {
            this.filters.setInitialFilters()
        }
        // Reorder fields based on group by
        // Recover order pending...

        this.group_by.forEach(function(fname, idx){
            const field = this.get_field_by_name(fname)
            this.fields.splice(this.fields.indexOf(field), 1)
            this.fields.splice(idx,0,field)


        }.bind(this))



        return this.data.loadData(count, reset_page)

    }
    /**
* Set Limit. 
* @param {int} value - limit value
* @return {void} 
    */

    set_limit(value) {
        this.data.setLimit(value)
        // this.do_search()
    }

    set_ordered_column(fname,direction){
        this.ordered_column = {name:fname,order:direction}
        this.order = [[fname, direction]]
}

    /**
* Set search order and dispatch search. 
* @param {string} field_name - field name to order by
* @param {string} direction - one of [ASC,DESC]. If undefined, the current direction will be toogled
* @param {boolean} dispatch - If true, a search with the new order will be dispatched.
* @param {boolean} navigate - If false, the action will not navigate (used on mobile and headless web views)
* @return {void} 
*/
    async set_order(field_name, direction, dispatch=true, navigate=true) {
        const search = { ...this.search_state }

        direction = direction ? direction : this.ordered_column.order === 'ASC' ? 'DESC' : 'ASC'
        search['order'] = [[field_name, direction]]
        //TODO: Review args on create url. currently receives search instead of current_search
        search['search'] = search.current_search
        
        this.set_ordered_column(field_name,direction)
        if(dispatch){
            if(navigate && this.navigate){
                let url = this.connection.createUrl(search)
                this.navigate(url)
            }
            else{
                this.do_search(search)
            }

        }
        
        

    }

    async set_group_by(field_names, dispatch=true, navigate=true) {
        const search = { ...this.search_state }
        const group_by = field_names.filter((value, index, array) => array.indexOf(value) === index);
        search['group_by'] = group_by
        //TODO: Review args on create url. currently receives search instead of current_search
        search['search'] = search.current_search
        
        if(dispatch){
            if(navigate && this.navigate){
                let url = this.connection.createUrl(search)
                this.navigate(url)
            }
            else{
                this.do_search(search)
            }

        }
        
        

    }

    set_title(title) {

        this.title = title ? title : this.title
    }
    set_active_record(record) {
        let change = record.id != this.active_record.id
        this.active_record = record;
        if(change){
            this.on_set_active_record(record)
            if(record){
                record.get_resources_count()
            }
            
        }
        
        
        

    }

    async on_set_active_record(record){
        const fields = this.record_dependant_fields
        if(!fields.length){return}
        const abortController = new AbortController();
        let args = {
            'view_id':this.id,
            'record':record.id,
        }
        let extended_fields = await this.connection.dispatch('GET', '/view/extended_fields', args, false, false, false, abortController)
        for(let field_id in extended_fields){
            let field = this.get_field_by_id(field_id)
            if(field){
                field.set_childs(extended_fields[field_id])
            }
            

        }
        
    }

    get_record_by_index(index) {
        return this.data.get_record_by_index(index)
    }
    get_record_by_id(id) {
        return this.data.get_record_by_id(id)
    }
    get_field_by_name(fname){
        return this.fields.find(f => f.name == fname)
    }
    get_field_by_id(field_id){
        return this.fields.find(f => f.id == field_id)
    }
    get_field_by_original_name(original_name){
        return this.fields.find(f => f.original_name == original_name)
    }
    get_field_by_any_name(name){
        let field = this.get_field_by_name(name)
        if(!field){
            field = this.get_field_by_original_name(name)
        }
        return field
    }
    get_action_by_id(id){
        return this.actions.find(f => f.id == id)
    }
    


    new_record(values) {
        const new_record = new Record(values, this);
        this.records.push(new_record)
        this.set_active_record(this.records[this.records.indexOf(new_record)])
    }

    createFields(fields) {
        let screen_fields = []
        fields.forEach(function(field){
            let new_field = createField(field, this)
            let complementary_fields = new_field.get_complementary_fields()
            if(complementary_fields){
                complementary_fields.forEach(function(f){
                    screen_fields.push(f)
                })
                
            }
            screen_fields.push(new_field)
            
        }.bind(this))

        this.fields = screen_fields

    }

    extend_fields(fields){
        this.fields = [...this.fields, ...fields]
    }

    reset_fields(){
        alert("reset fields")
        this.fields = []
    }

    async loadFields(search={}, pivot_values={}, no_cache=false, extra_args={}) {
        if (!this.initialize_fields) {
            return false
        }
        let cache = no_cache ? false:this.fields_cache
        
        let args = { 
                    view_id: this.id, 
                    search:search.current_search || [], 
                    pivot_values:pivot_values, 
                    model:this.model,
                    environment:environment(),
                    ...search
                }
        
        const abortController = new AbortController();
        let all_fields = await this.connection.dispatch('GET', '/view/fields', args, false, cache, false, abortController, extra_args)
        
        runInAction(() => {
            this.createFields(all_fields)
            this.loadingFields = false;


        })



    }

    add_actions_rec_names(){
        if(this.attachment_list_action){
            this.attachment_list_action.description_getter = ()=>{
                if(!this.active_record){
                    return this.attachment_list_action.original_description
                }
                const attachment_quantity = this.active_record.attachments_count
                return  this.attachment_list_action.original_description + " ( " + attachment_quantity + " )"
            }
        }
        if(this.notes_list_action){
            this.notes_list_action.description_getter = ()=>{
                if(!this.active_record){
                    return this.notes_list_action.original_description
                }
                const notes_quantity = this.active_record.notes_count
                return  this.notes_list_action.original_description + " ( " + notes_quantity + " )"
            }
        }
    }

    createActions(actions) {
        this.actions = actions.map(function (a) {
            return new Action(a, this)
        }.bind(this))
        this.add_actions_rec_names()

    }
    set_actions_map(actions_map) {
        this.actions_map = actions_map
    }

    async loadActions(cache=true) {
        if (!this.initialize_actions) {
            return false
        }
        // TODO: Review when caching policy gets improved
        // Modals not stored on screen groups, so cache actions to improve perf
        if(!cache && this.modal){
            cache=true
        }
        let args = { 
            view_id: this.id,
            environment:environment()
         }
        const abortController = new AbortController();
        let new_actions = await this.connection.dispatch('GET', '/view/actions', args, false, cache, false, abortController)


        runInAction(() => {
            this.createActions(new_actions)
        })


    }
    /**
* Add style to be used by screen components. 
* Supported Components:
* * Modal
* @param {object} style - object with component as key and style to apply as value
* @return {void} 
*/
    add_style(style) {
        this.style_modifiers.styles = { ...this.style_modifiers.styles, ...style }

    }
    /**
* Add classname to be used by screen components. 
* Supported Components:
* * Modal
* @param {object} classname - object with component as key and classname(str) to apply as value
* @return {void} 
*/
    add_classname(classname) {

        this.style_modifiers.classnames = { ...this.style_modifiers.classnames, ...classname }
    }

    complete_layout_breakpoints(new_layouts){
        const current_status = this.layout
        const current_breakpoints = current_status.breakpoints
        let inherited_breakpoints = current_status.inherited_breakpoints || []
        const current_breakpoint = this.editor.current_breakpoint
        // remove from inherited if current_breakpoint its being updated
        if(inherited_breakpoints.includes(current_breakpoint)){
            inherited_breakpoints.splice(inherited_breakpoints.indexOf(current_breakpoint), 1)
        }
        for(let breakpoint of Object.keys(current_breakpoints)){
            if(!new_layouts.hasOwnProperty(breakpoint) || inherited_breakpoints.includes(breakpoint)){
                // Copy from default layout(breakpoint) to all inherited or empty breakpoints
                new_layouts[breakpoint] = new_layouts['default']
                if(!inherited_breakpoints.includes(breakpoint)){
                    inherited_breakpoints.push(breakpoint)
                }
                
            }

        }
        return {new_layouts, inherited_breakpoints}
    }

    merge_current_breakpoint(layouts){
        const current_breakpoint = this.editor.current_breakpoint
        if(layouts[current_breakpoint] && this.layout.layouts[current_breakpoint]){
            let new_layout = layouts[current_breakpoint]
            this.layout.layouts[current_breakpoint].forEach(function(field){
                if(!new_layout.find(f => f.i === field.i)){
                    new_layout.push(field)
                }
            })
        }
        return layouts
    }

    async save_view_layout(layouts, current_breakpoint){
        layouts = this.merge_current_breakpoint(layouts)
        const {new_layouts, inherited_breakpoints} = this.complete_layout_breakpoints(layouts)
        const new_layout = {...this.layout, ...{'layouts':new_layouts, 'inherited_breakpoints':inherited_breakpoints}}
        const abortController = new AbortController();
        let args = { 
            view_id: this.id,
            layout:JSON.stringify(new_layout)
        }
        await this.connection.dispatch('POST', '/view_handler/save_view_layout', args, false, false, false, abortController)
        

        
    }


    set_context(context){
        this.context = {...this.context, ...context}
    }

    get_widget(field_type, name, definition=false){
        if(!this.group || !this.group.widgets_registry){
            return false
        }
        if(definition){
            return this.group.widgets_registry.get_definition(field_type, name)
        }
        return this.group.widgets_registry.get(field_type, name)
    }

   get_visible_fields(record){
        if(!record){
            return []
        }
        return this.visible_fields.filter(field => field.is_visible(record))
    }

    add_sub_screen(sub_screen){
        this.sub_screens[sub_screen.id] = sub_screen
    }
    async _delete_filter(filter_ids){
        
        let args = { ids: filter_ids }
        const abortController = new AbortController();
        let res = await this.connection.dispatch('POST', '/view_handler/view/filter/delete', args, false, false, false, abortController)

        if(res){
            await this.reinitialize()
            this.notifications.addSnack(
                { message: "Filter Deleted", level: 'error',timeout:2000 }
            )
            
        }
    }
    async deleteFilter(filter_ids){
        this.notifications.addDialog({
            'message':'Are you sure you want to delete this filter ?', 
            'html':true,
            'actions':[
                {'name':'OK', 'color':'primary', 'callback':()=>{
                    this._delete_filter(filter_ids)
                    return true
                }}
            ]}, 
            true)  

    }

    async update_view_attributes(attributes, setattr=true){
        let values = {}
        values[this.id] = attributes
        if(setattr){
            for(let attr in attributes){
                this['set_'+attr](attributes[attr])
            }
        }
        
        let args = { 
            values:values,
        }
        const abortController = new AbortController();
        await this.connection.dispatch('POST', '/view_handler/view/update', args, false, false, false, abortController)

    }


}



