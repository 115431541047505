import { makeAutoObservable, autorun, runInAction } from "mobx"
import { downloadFile, evalExpr, validateNumber, browseObject, domain_to_object } from '../common'
import { Record } from "./Record"
import { environment } from "../common"
// import uuid from "node-uuid"
// TODO: Review record vs selected_records => needs to be unified
// TODO: Split in classes by action type
const AsyncFunction = Object.getPrototypeOf(async function(){}).constructor
export class Action {
    default = false
    default_save = false
    description = ""
    id = ""
    key_fields = ""
    modal = null
    multiple_records = false
    name = ""
    show_button = false
    target_title = ""
    target_view = ""
    type = ""
    visible_on_select = false
    return_action = null
    report_ids = ""
    report_data = ""
    target_view_definition = {}
    target_view_key = ""
    target_view_field = ""
    pre_action = ""
    post_action = ""
    custom_method_op = ""
    custom_validation = false
    requires_confirmation = false
    confirmation_message = false
    show_success_message = false
    success_message_type = ""
    success_message = ""
    invisible = false
    custom_path = ''
    report_filename = ''
    modal_style = ''
    custom_frontend_function = ''
    is_async = false
    description_getter = false

    get keywords(){
        return {
            '_model':this.screen.model
        }
    }

    get rec_name(){
        if(!this.description_getter){
            return this.original_description
        }
        return this.description_getter()
        // const attachment_quantity = this.screen.active_record.resources_count.attachments.total
        // return  this.original_description + "( " + attachment_quantity + " )"
    }
    constructor(attributes, screen) {
        makeAutoObservable(this)
        this.screen = screen
        this.default = attributes.default || false
        this.default_save = attributes.default_save || false
        this.description = attributes.description || ""
        this.original_description = attributes.description || ""
        this.id = attributes.id || ""
        this.key_fields = attributes.key_fields || ""
        this.modal = attributes.modal || null
        this.multiple_records = attributes.multiple_records || false
        this.name = attributes.name || ""
        this.show_button = attributes.show_button || false
        this.target_title = attributes.target_title || ""
        this.type = attributes.type || ""
        this.visible_on_select = attributes.visible_on_select || false
        this.target_view = attributes.target_view
        this.target_view_key = attributes.target_view_key
        this.target_view_definition = attributes.target_view_definition
        this.target_view_field = attributes.target_view_field
        this.return_action = attributes.return_action
        this.report_ids = attributes.report_ids
        this.report_data = attributes.report_data || ""
        this.report_filename = attributes.report_filename || ""
        this.pre_action = attributes.pre_action
        this.post_action = attributes.post_action
        this.custom_method_op = attributes.custom_method_op
        this.custom_method_args = attributes.custom_method_args || []
        this.custom_validation = attributes.validation_function ? new Function('records', attributes.validation_function) : false
        this.requires_confirmation = attributes.requires_confirmation
        this.confirmation_message = attributes.confirmation_message
        this.show_success_message = attributes.show_success_message
        this.success_message_type = attributes.success_message_type
        this.success_message = attributes.success_message
        this.include_current_search = attributes.include_current_search
        this.invisible = attributes.invisible ? attributes.invisible:false
        this.custom_path = attributes.custom_path
        this.batch_records_field = attributes.batch_records_field
        this.modal_style = attributes.modal_style ? JSON.parse(attributes.modal_style):false
        this.custom_frontend_function = attributes.custom_frontend_function ? new AsyncFunction('action,records', attributes.custom_frontend_function) : false
        this.is_async = attributes.is_async
        this.keep_field_values = attributes.keep_field_values
        if(this.type == 'export_data'){
            this.description_getter = this.descriptionWithCount
        }

    }

    descriptionWithCount(){
        if(this.screen && 
            this.screen.selected_records &&
            this.screen.selected_records.length
            ){
            return this.original_description + ' ( ' + this.screen.selected_records.length + ' )'
        }
        return this.original_description
    }


    getKeyFields() {
        return this.key_fields.split(";")
    }

    /**
 * Map the new saved values to records.
 * @param {array} old_records - array of Records.
 * @param {array} new_records - array of objects with the shape: {record_id:new_values}
 * @return {array} Array of Records with new values
 */
    mapNewRecords(old_records, new_records = []) {

        let recs = old_records.map(function (r) {
            let attrs = new_records.find(n => Object.keys(n)[0] == r.id)
            let new_record = new Record(attrs[r.id], this.screen, this.group)
            this.screen.data.replaceRecord(r, new_record)
            return new_record

        }.bind(this))

        return recs
    }

    /**
* Save the records.
* @param {array} records - array of Records to be saved.
* @return {array} Array of Records with new values and ids
*/
    async executeCreateUpdate(records, async_reload) {
        let prm = []
        records.forEach(function (rec) {
            prm.push(rec.save(this))
        }.bind(this))
        //HERE => Map the old values to new ones, avoiding the need to map everywhere
        prm = await Promise.all(prm)
        if (Array.isArray(prm) && prm.some(r => r === false)) {
            prm = false
        }
        else {
            prm = this.mapNewRecords(records, prm)
            this.screen.reload_field_childs()
        }
        
        
        
        
        return prm


        // return Promise.all(prm)


    }
    /**
* Delete records.
* @param {array} records - array of Records to be deleted.
* @return {void} 
*/

    async executeDeleteRecord(records) {
        const selected_records = records ? records : this.screen.selected_records;
        let args = {}
        let res = {}
        args['ids'] = selected_records.filter(function(r){return r.id>0}).map(function(rec){return rec.id})
        args['view'] = this.screen.id
        if(this.screen.data_origin != "none"){
            if(args['ids'].length){
                const abortController = new AbortController()
                res = await this.screen.connection.dispatch('POST', '/data/delete', args, false, false, true, abortController)
                
            }
            if(args['ids'].length 
                && res['result'] !== null
                ){
                return false
            }

        }
        
        
        this.screen.data.removeRecords(selected_records)
        
        return res

        // request('POST', '/delete', args, false, false, true, abortController)
    }

    async loadTargetDefinition(target_id){
        const abortController = new AbortController();
        let args = { view_id: target_id,
                    environment:environment()
                    }
        let new_definition = await this.screen.connection.dispatch('GET', '/view/definition', args, false, false, false, abortController)
        return new_definition
    }

    /**
*  Navigates to other route
    If key_fields, uses screen selected_records
* @return {void} ""
*/
    async executeLinkAction(records, initialize_callback, caller_reference=false, extra_search=[]) {

        const selected_records = records ? records : this.screen.selected_records;

        if (this.visible_on_select && selected_records.length < 1) {
            this.screen.notifications.addSnack(
                { message: "Seleccione un registro para ejecutar esta acción", level: 'error' }
            )
            return false
        }
        if (!this.multiple_records && selected_records.length > 1) {
            this.screen.notifications.addSnack(
                { message: "Seleccione sólo un registro para ejecutar esta acción", level: 'error' }
            )
            return false
        }

        const get_object_value = (record, fname) =>{
            let value;
            let parent_record = this.screen.parent ? this.screen.parent.active_record : false
            if(parent_record){
                value = browseObject(record.get_all_values(), fname, {}, [], parent_record.get_all_values())
            }
            else{
                value = browseObject(record.get_all_values(), fname, {}, [], {})
            }
                
            return value


        }
        
        const field_value = (records, fname, getter=false) =>{
            let val;
            if(!getter){
                getter = (record, fname)=>{return record.get_value(fname)}
            }
            if(!records.length){
                return
            }
            if(records.length > 1){
                val = records.map(function(record){return getter(record, fname)})
            }
            else{
                // val = records[0].get_value(fname)
                val = getter(records[0], fname)
                if(this.multiple_records){
                    val = [val]
                }
                
                
            }
            return val;
        }

        const target_title = (records, expression) =>{
            let title = ""
            if(records.length > 1){
                
                let variables = records.map(function(record){return evalExpr(record._values, expression).variables || ""})
                let to_replace = expression.match(/{([^}]+)}/g)
                title = expression
                to_replace.forEach(function(field){
                    title = title.replace(field,"")
                })
                title += variables.map(function(variable){
                    let text = ""
                    for(let k in variable){
                        text+= variable[k].toString()
                    }
                    return text
                }).toString()
                
            }
            else{
                title = evalExpr(records[0]._values, expression).str || ""
            }
            return title;
        }
      

        

        const key_fields = this.getKeyFields()
        
        
        const search = { current_search: [], action_params: [], action_id: this.id }
        if(this.include_current_search){
            search.current_search = this.screen.current_search
        }
        if(extra_search){
            search.current_search = [...search.current_search, ...extra_search]
        }
        
        if (key_fields) {
            key_fields.forEach(function (field) {

                if (field.split('.').length > 1) {
                    let spl_field = field.split('.')
                    if(spl_field && records.length){
                        search.action_params.push([field, '=', field_value(selected_records,field, get_object_value)])
                    }
                    //TODO: Add support for browseObject

                    // throw new Error("Invalid Key field " + field);
                    
                }
                else if(this.keywords.hasOwnProperty(field)){
                    search.action_params.push([field,'=',this.keywords[field]])
                }
                else {
                    if (field) {
                        if(records.length){
                            search.action_params.push([field, '=', field_value(selected_records,field)])
                        }
                        
                    }

                }


            }.bind(this))
        }

        search['_action_id'] = { value: this.id }

        // function get_target_from_record(){
        //     get_object_value(selected_records[0], this.target_view_field)
        // }

        let target_view_id = this.target_view ? this.target_view:get_object_value(selected_records[0], this.target_view_field)
        console.log("TARGET VIEW ID ON LINK ACTION")
        console.log(target_view_id)
        let route = this.screen.connection.getRouteById(target_view_id)
        console.log("THE ROUTE GETTED")
        console.log(route)
        
        if (!route) {
            this.screen.notifications.addSnack(
                { message: "No tiene acceso a esta vista.", level: 'error' }
            )
            return false
        }
        



        let route_state = {}

        if (this.target_title && selected_records.length) {
            route_state[this.id] = {}
            const title = target_title(selected_records,this.target_title)
            
            route_state[this.id]['title'] = title

        }
        const navigate = this.screen.navigate ? this.screen.navigate:this.screen.root_screen.navigate
        // if (this.modal || !this.screen.navigate) {
        if (this.modal || !navigate) {
            // view, history, initial_search, route_state = {}, set_active = true, is_modal = false, parent = false, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false
            let target_definition = this.target_view_definition ? this.target_view_definition:await this.loadTargetDefinition(target_view_id)
            let view_definition = {...target_definition, parent_ref:caller_reference}
            const include_context = true
            let context = include_context ? {...domain_to_object(search.current_search),...this.screen.search_context}:{}
            const target_screen = this.screen.modal_childs.addScreen(view_definition, false, search, route_state, true, true, this.screen, true, true, true,initialize_callback,false,false, context)
            if(this.modal_style){
                target_screen.add_style({'modal':this.modal_style})    
            }
            return target_screen
            
        }
        else {
            // if the route has a path, means its web environment. 
            const path = route.path ? route.path.concat(this.screen.connection.createUrl(search)):route.name
            const params = route.path ? route_state:search
            

            // this.screen.navigate(path, params)
            navigate(path, params)


        }

        return true


    }

    async executeExportData(records) {

        const abortController = new AbortController();
        let params = {...this.screen.search_state}
        const notification = this.screen.notifications.addSnack(
            { message: "Exportación en curso...", persistant: true }
        )
        const selected_records = records ? records : this.screen.selected_records;
        const active_ids = selected_records.map(function(rec){return rec.id})
        params['search'] = this.screen.current_search
        params['action_params'] = this.screen.action_params
        params['action_id'] = this.id
        params['origin_action_id'] = this.screen.action_id ? this.screen.action_id : ''
        if(active_ids.length){
            params['active_ids'] = active_ids
        }
        params = {...params, ...this.screen.data.get_pagination_status()}
        let res = await this.screen.connection.dispatch('GET', '/data/export', params, false, false, true, abortController)
        notification.remove()
        if(res && res.data){
            downloadFile(URL.createObjectURL(res.data), this.screen.title)
            this.screen.notifications.addSnack(
                { message: "Descarga Completa" }
            )
        }
        
        
        


        return res

    }

    async executeReportAction(records) {
        const notification = this.screen.notifications.addSnack(
            { message: "Reporte en progreso...", persistant: true }
        )
        const selected_records = records ? records : this.screen.selected_records;
        let args = {}
        args.action_id = this.id


        args.ids = []
        selected_records.forEach(function (record) {
            let ids_value = record.get_value(this.report_ids)
            if (Array.isArray(ids_value)) {
                ids_value.forEach(function (id) {
                    args.ids.push(id)
                })
            }
            else {
                args.ids.push(ids_value)
            }

        }.bind(this))

        //get report_data
        args['data'] = {}
        selected_records.forEach(function (r) {
            let expr = evalExpr({ ids: args.ids, ...r._values }, this.report_data.substring(1, this.report_data.length - 1))
            if (expr.str) {

                args['data'] = JSON.parse('{' + expr.str + '}')
            }
        }.bind(this))


        const abortController = new AbortController();

        let {data, filename} = await this.screen.connection.dispatch('GET', '/actions/report', args, false, false, true, abortController)
        if(this.report_filename){
            filename = this.report_filename
        }
        else if(!filename){
            filename = "Report"
        }
        if(!data){
            notification.remove()
            return
        }
        if(this.screen.fileHandler){
            notification.remove()
            return this.screen.fileHandler(data)
        }
        else{
            
            notification.remove()
            return downloadFile(URL.createObjectURL(data), filename)
        }
        

    }

    /**
* Executes a classmethod on the model. If the view has a default_save action, 
* the record is saved before classmethod execution. If this operation fails,the classmethod is not executed.
* @param {array} records - Array of records. If undefined, the current screen selected records will be used
* @return {array} Array of Records with new values after classmethod execution
*/
    async executeClassMethod(records) {
        let selected_records = records ? records : this.screen.selected_records;
        let new_recs = selected_records
        let save_action = this.screen.default_save_action
        let args = {}
        args.values = {}
        args['action_id'] = this.id
        const abortController = new AbortController();
        if (save_action) {
            new_recs = await this.executeCreateUpdate(selected_records)

        }
        else{
            new_recs = selected_records.map(function(rec){
                if(rec.validate()){
                    return rec
                }
                else{
                    return false
                }
            })
        }
        if (Array.isArray(new_recs) && new_recs.some(r => r === false)) {
            return false
        }
        if (!new_recs) {
            return false
        }
        const key_fields = this.getKeyFields()
        // selected_records = this.mapNewRecords(selected_records, new_recs)
        


        key_fields.forEach(function (fname) {
            if (["ids", "records"].includes(fname)) {
                args.values[fname] = new_recs.map(function (r) { return r.id })
            }
            else{
                if(this.multiple_records){
                    args.values[fname] = new_recs.map(function (r) { return r.get_value(fname) })
                }
                else{
                    let key_value = new_recs[0].get_all_values()[fname]

                    if(!key_value && !new_recs[0]._values.hasOwnProperty(fname)){
                        // Recover key field value from original, use case for the non model fields (used as "wizard")
                        key_value = selected_records[0].get_value(fname)
                    }
                    args.values[fname] = key_value
                }
                
            }
            
            
        }.bind(this))

        let res = await this.screen.connection.dispatch('POST', '/actions/classmethod', args, false, false, false, abortController)
        
        if(!res){
            return false
        }
        const new_records = this.mapNewRecords(new_recs, res)
        if(!this.return_action){
            this.screen.reload_field_childs()
        }
        return new_records





    }
    async executeBatchUpdate(records){
        let args = {}
        let selected_records = records ? records : this.screen.selected_records;
        if(selected_records.length > 1){
            let message = "This action can't be executed from multiple records"
            this.screen.notifications.addSnack(
                { message: message, level: 'error',timeout:5000 }
            )
            return false
        }
        const record = selected_records[0]
        let valid = record.validate()
        const records_field = this.screen.get_field_by_id(this.batch_records_field)

        if(!valid || !records_field){
            return false
        }

        args['action_id'] = this.id
        let values = record.get_all_values()
        args['data'] = values
        args['records'] = values[records_field.name]
        const abortController = new AbortController();
        let action_result = await this.screen.connection.dispatch('POST', '/actions/batch_update', args, false, false, false, abortController)
        return action_result
    }

    async executeCustomMethod(records) {
        let args = {}
        const path = this.custom_path ? this.custom_path:'/actions/custom_action'
        let selected_records = records ? records : this.screen.selected_records;
        args['action_id'] = this.id
        const fnames = this.custom_method_args
        if(this.screen.modal && this.screen.parent){
            args['_parent'] = {
                'view_id':this.screen.parent.id,
                'active_record': this.screen.parent.active_record ? this.screen.parent.active_record.id:false
            }
        }

        args['data'] = selected_records.map(function (record) {
            if (record.validate()) {
                if(fnames.length){
                    let vals = {}
                    fnames.forEach(function(fname){
                        vals[fname]= record.get_value(fname)

                    })
                    return vals
                }
                else{
                    return record.get_all_values()
                }
                
            }
            else {
                return false
            }

        })
        
        //return false if any record is not valid
        if (Array.isArray(args['data']) && args['data'].some(r => r === false)) {
            return false
        }

        const abortController = new AbortController();
        let action_result = await this.screen.connection.dispatch(this.custom_method_op, path, args, false, false, false, abortController)
        if(!this.return_action){
            this.screen.reload_field_childs()
        }
        
        return action_result

    }

    async executeCustomFrontendMethod(action, records){
        try {
            return await this.custom_frontend_function(action, records)
            // return await this.temp_custom_frontend_action(action, records)
            
        }
        catch (e) {
            let message = "A problem ocurred with the custom  action : " + action.name + '  =>  '
            message += (e.toString())
            // throw new Error(message)
            this.screen.notifications.addSnack(
                { message: message, level: 'error', timeout: 5000 }
            )
        }
    }

    async executeSendMail(records){
        
        const args = {}
        args['action_id'] = this.id
        args['data'] = records.map(function (record) {
            if (record.validate()) {
                return record.get_all_values()
            }
            else {
                return false
            }

        })
        if (Array.isArray(args['data']) && args['data'].some(r => r === false)) {
            return false
        }
        
        const abortController = new AbortController();
        let action_result = await this.screen.connection.dispatch('POST', '/actions/send_mail', args, false, false, false, abortController)
        return action_result


    }

    async save_active_record(records) {
        let selected_records = records ? records : this.screen.selected_records;
        let save_action = this.screen.default_save_action
        if(!save_action){
            return
        }
        return save_action.execute(selected_records)
        // return new Error("Save Active Record: Implementation Pending")

    }

    validate_records(records) {
        let valid = true
        let recs = records.map(function (record) {
            return record.validate()

        })
        //return false if any record is not valid
        if (Array.isArray(recs) && recs.some(r => r === false)) {
            valid = false
        }
        return valid
    }

    /**
* Saves the parent record and update default values (based on parent) on the given records.
* The parent record is only reloaded if a linked return action is attached to save.
* @param {array} records - Array of records. If undefined, the current screen selected records will be used
* @return {array} new (saved) parent records or false
*/
    async save_parent_record(records) {
        let save_action = this.screen.parent.default_save_action
        if (!save_action) {
            return false
        }
        let selected_records = records ? records : this.screen.selected_records;
        let new_recs = selected_records

        new_recs = await save_action.execute([this.screen.parent.active_record])

        records.forEach(function (record) {
            //Get the parent defaults again, with the saved parent
            const parent_defaults = record.get_parent_defaults(new_recs[0])
            record.set_values(parent_defaults)
        })
        return new_recs

    }

    async executePreAction(records) {

        if (!this.validate_records(records)) {
            return false
        }

        if (this.pre_action === 'save') {
            return await this.save_active_record(records)
        }
        else if (this.pre_action === 'save_parent') {
            return await this.save_parent_record(records)
        }


    }


    async execute_custom_validation(records){
        let valid = false;
        try{
            valid = this.custom_validation(records)
        }
        catch(e){
            let message = "A problem ocurred with the custom validation method on action: " + this.name + '  =>  '
            message+=(e.toString())
            this.screen.notifications.addSnack(
                { message: message, level: 'error',timeout:5000 }
            )
        }
        

        return valid;
    }

    showSuccessMessage(){
        
        if(this.success_message_type === 'snack'){
            
            this.screen.notifications.addSnack(
                { message: this.success_message, level: 'sucessfull',timeout:5000 }
            )
        }
        else if(this.success_message_type == 'dialog'){
            this.screen.notifications.addDialog({
                'message':this.success_message, 
                'html':true,
                'actions':[]
            }, 
                true)  
        }
    }

    toogleProcessing(value=null){
        if(value==null){
            value = !this.screen.processing
        }

        this.screen.set_processing(value)
        if(this.screen.parent){
            this.screen.parent.set_processing(value)
        }
    }
    
    /**
    * Execute action
    * @param {array}  records - Array with records related to the action. 
    * @param {boolean}  async_reload - --- 
     
    * @return {void} ""
    */
//    TODO: Improve records validation
    async execute(records, async_reload,confirmed=false, executed=false, initialize_callback=false, caller_reference=false, extra_args=[]) {

        let res = ""
        records = records ? records : this.screen.selected_records;
        if(!records){
            records = [this.screen.active_record]
        }
        if (!this.multiple_records && records.length > 1) {
            this.screen.notifications.addSnack(
                { message: "Seleccione sólo un registro para ejecutar esta acción", level: 'error' }
            )
            return false
        }
        if (this.screen.actions_map.hasOwnProperty(this.name) && !executed) {
            if (this.validate_records(records)) {
                return this.screen.actions_map[this.name](records)
            }

        }
        if(this.custom_validation){
            if(this.validate_records(records)){
                let is_valid = await this.execute_custom_validation(records)
                if(!is_valid){
                    return false
                }
            }
            else{
                return false
            }

           
        }

        if(this.requires_confirmation && !confirmed){
            this.screen.notifications.addDialog({
                'message':this.confirmation_message, 
                'html':true,
                'actions':[
                    {'name':'OK', 'color':'primary', 'callback':()=>{
                        this.execute(records,false,true)
                        return true
                    }}
                ]}, 
                true)    
            return false
        }

        if (this.pre_action) {
            await this.executePreAction(records)

        }
        // Needs review: The async function hydrated with backend values throws error inside switch on prod build.
        if(this.type == 'custom_frontend_method'){
            res = await this.executeCustomFrontendMethod(this, records)
        }
        else{
            switch (this.type) {
                case 'create_update':
                    res = await this.executeCreateUpdate(records, async_reload);
                    break;
                case 'batch_update':
                    res = await this.executeBatchUpdate(records)
                    break;
                case 'link':
                    res = await this.executeLinkAction(records, initialize_callback, caller_reference, extra_args)
                    break;
                case 'export_data':
                    res = await this.executeExportData()
                    break;
                case 'report':
                    res = await this.executeReportAction(records)
                    break;
                case 'classmethod':
                    this.toogleProcessing(true)
                    res = await this.executeClassMethod(records)
                    this.toogleProcessing(false)
                    break;
                case 'custom_method':
                    this.toogleProcessing(true)
                    res = await this.executeCustomMethod(records)
                    this.toogleProcessing(false)
                    break;
                case 'delete_record':
                    res = await this.executeDeleteRecord(records)
                    break;
                case 'send_email':
                    res = await this.executeSendMail(records)
                    break;
                
            }
        }
        

        // //Some action is not executed, example: record is not valid, avoid return actions
        // if (Array.isArray(res) && res.some(r => r === false)) {
        //     return false
        // }
        
        // TODO: Refactor, to many post actions for this approach
        if (res && this.post_action) {
            if (this.post_action === 'close_reload') {
                this.screen.reload_parent()
                // this.screen.reload_root_screen()
                this.screen.group.removeScreen(this.screen.id)
                
            }

            else if (this.post_action === 'close_dialog'){
                this.screen.group.removeScreen(this.screen.id)
            }
            else if (this.post_action == 'clean_reload'){
                this.screen.reload_parent()
                let kept_values = {}
                this.keep_field_values.forEach(function(fname){
                    kept_values[fname] = this.screen.active_record.get_value(fname)
                }.bind(this))
                const new_record = this.screen.data.addRecord({'values':{}})
                new_record.set_values(kept_values)
                this.screen.data.replaceRecord(this.screen.active_record, new_record)

            }
            else if (this.post_action === 'reload_parent') {
                this.screen.reload_parent()
            }
            else if (this.post_action === 'reload_root_parent'){
                this.screen.reload_root_screen()
            }
            else if (this.post_action === 'close_reload_root_parent'){
                this.screen.reload_root_screen()
                this.screen.group.removeScreen(this.screen.id)
            }
            else if (this.post_action === 'update_record') {
                
                //TODO: REVIEW
                if(res){
                    // records.forEach(function (r) {
                    //     r.set_values(res[r.id])
                    // })
                    
                    res = this.mapNewRecords(records, [res])
                    
                    
                }
                
                
            }
        }
        if(res && this.show_success_message){
            this.showSuccessMessage()
        }
        if(this.is_async){
            return res
        }
        if (res && this.return_action) {
            let return_action = new Action(this.return_action, this.screen)
            // let recs = this.mapNewRecords(records, res)

            return_action.execute(res).then(function (a) {
                if (this.type === 'classmethod' || this.type == 'custom_method' ) {

                    this.screen.reload_field_childs()
                }
            }.bind(this))

        }
        

        return res
    }




}
