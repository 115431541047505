import { action, makeObservable, observable, computed, toJS } from "mobx"
import {grid_layout_defaults} from "../ui/ui_defaults"

export class ScreenLayout {
    screen = {}
    layouts = {}
    cols = {}
    breapoints = []
    margin = []
    changed = false
    compact_type = 'vertical'
    allow_overlap = false
    focused_element = ''

    get default_layout(){
        const visible_elements = this.screen.type == 'board' ? this.screen.widgets:this.screen.visible_fields
        const layouts = {'default':
        visible_elements.map(function(element){
            return { x: 0,
                    y: 0,
                    w: grid_layout_defaults.default_colspan,
                    h: element.default_height,
                    i:element.original_name}
                }
        )}

        return {
            'layouts':layouts,
            'breakpoints':grid_layout_defaults.breakpoints,
            'cols':grid_layout_defaults.cols,
            'margin':grid_layout_defaults.margin,
            'allow_overlap':false

        }
        
    }

    get definition(){
        return {
            'layouts':this.layouts,
            'breakpoints':this.breakpoints,
            'cols':this.cols,
            'margin':this.margin,
            'inherited_breakpoints':this.inherited_breakpoints,
            'allow_overlap':this.allow_overlap
        }
    }

    get definition_clone(){
        return {
            'layouts':toJS(this.layouts),
            // 'breakpoints':this.breakpoints,
            // 'cols':this.cols,
            // 'margin':this.margin,
            // 'inherited_breakpoints':this.inherited_breakpoints,
            // 'allow_overlap':this.allow_overlap
        }
    }

    get current_cols(){
        if(!this.cols){
            return 200
        }
        return this.cols[this.editor.current_breakpoint]
    }

    get x_margin(){
        return this.margin[0]
    }
    get y_margin(){
        return this.margin[1]
    }

    get current_allow_overlap(){
        return {
            'value':this.allow_overlap,
            'label':this.allow_overlap ? 'Si':'No'
        }
    }

    get current_layout(){
        // const active_record = this.screen.active_record
        if(this.editor.design_mode || this.screen.type == 'board'){
            console.log("RETURNING NEW LAYOUT")
            return this.definition_clone
        }
        
        

        let view_layout = this.definition_clone
        let layouts_copy = view_layout.layouts
        const breakpoint = this.editor.current_breakpoint && layouts_copy[this.editor.current_breakpoint] ? this.editor.current_breakpoint:'default'
        
        if(!layouts_copy){
            return this.layouts
        }
        // const visible_elements = this.screen.get_visible_fields(this.screen.active_record).map(function (f) { return f.original_name })
        const visible_elements = this.screen.currently_visible_fields.map(function (f) { return f })
        const visible_elements_names = visible_elements.map(function (f) { return f.original_name })
        const collapsed_elements = visible_elements.filter(item => item.collapsed == true).map(function(element){return element.original_name})
        let filtered_layout = [...layouts_copy[breakpoint]].filter(item => visible_elements_names.includes(item.i)).sort((a, b) =>  a.y - b.y);

        if(collapsed_elements.length){
            filtered_layout.forEach(function(element){
                if(collapsed_elements.includes(element.i)){
                    element['h'] = 1
                }
            })
        }


        layouts_copy[breakpoint] = filtered_layout
        view_layout['layouts'] = layouts_copy
        return view_layout
    }

    get ordered_element_names(){
        const current_layouts = this.current_layout.layouts
        const current_layout = current_layouts['mobile']
        let ordered_elements = []

        const visible_elements = this.screen.currently_visible_fields
                                    .filter(function(field){return (field.focusable)})
                                    .map(function (f) { return f.original_name })
        current_layout.forEach(function(row){
            ordered_elements = [...ordered_elements, 
                                ...row.map(function(element){
                                    if(visible_elements.includes(element.i)){
                                        return element.i
                                    }
                                    return null
                                    
                                })
                                ].filter(function(item){return item!=null})
            
        })
        return ordered_elements
    }

    get next_visible_element(){
        const current_idx = this.ordered_element_names.indexOf(this.focused_element);

        return this.ordered_element_names[current_idx+1]
    }

    
    constructor(screen, view_layout, save_layout=false) {
        this.screen = screen;
        this.editor = screen.editor
        // this.connection = screen.connection
        this.notifications =
        this.original_layout = view_layout
        if(!view_layout){
            view_layout = this.default_layout
        }
        view_layout = toJS(view_layout)
        this.layouts = view_layout.layouts
        this.breakpoints = view_layout.breakpoints
        this.cols = view_layout.cols
        this.margin = view_layout.margin || [10,10]
        this.inherited_breakpoints = view_layout.inherited_breakpoints
        this.changed = false
        this.changes_initialized = false
        this.compact_type = 'vertical'
        this.allow_overlap = view_layout.allow_overlap || false
        this.save_layout = save_layout
        this.focused_element = ''
        


        makeObservable(this,{
            layouts:observable,
            breakpoints:observable,
            cols:observable,
            margin:observable,
            changed:observable,
            inherited_breakpoints:observable,
            allow_overlap:observable,
            set_layouts:action,
            set_breakpoints:action,
            set_cols:action,
            set_margin:action,
            set_changed:action,
            set_inherited_breakpoints:action,
            set_allow_overlap:action,
            current_layout:computed,
            definition:computed,
            focused_element:observable,
            set_focused_element:action,


        })   
        
       

    }

    set_layouts(layouts){
        this.layouts = layouts
    }
    set_breakpoints(breapoints){
        this.breakpoints = breapoints
    }
    set_inherited_breakpoints(breakpoints){
        this.inherited_breakpoints = breakpoints
    }
    set_cols(cols){
        this.cols = cols
    }
    set_margin(value){
        this.margin = value
    }
    set_x_margin(value){
        value = value ? value:0
        let current_margin = [...this.margin]
        current_margin[0] = parseInt(value)
        this.set_margin(current_margin)
        this.set_changed(true)
    }
    set_y_margin(value){
        value = value ? value:0
        let current_margin = [...this.margin]
        current_margin[1] = parseInt(value)
        this.set_margin(current_margin)
        this.set_changed(true)
    }
    set_changed(value){
        this.changed = value
        if(value){
            if(this.screen.main_screen && this.screen.main_screen.view_layout){
                this.screen.main_screen.view_layout.set_changed(true)
            }
            else{
                this.editor.add_draft_operation('layout_change',{
                    'name':'Guardar Layout',
                    'value':[],
                    'callback':(layouts) =>{this.save_view_layout()}
                })
            }
            
            
        }
        
        
    }
    set_allow_overlap(value){
        this.allow_overlap = value
        this.set_changed(true)
    }

    set_breakpoint_cols(cols, breakpoint=false){
        if(!cols){
            return
        }
        breakpoint = breakpoint? breakpoint:this.editor.current_breakpoint
        let current_cols = {...this.cols}
        current_cols[breakpoint] = parseInt(cols)
        this.set_cols(current_cols)
    }

    
    
    set_layout_change(layouts){
        this.set_layouts(layouts)
        this.set_changed(true)
        
    }



    complete_layout_breakpoints(){
        const current_status = this.layouts
        const current_breakpoints = this.breakpoints
        let inherited_breakpoints = this.inherited_breakpoints || []
        const current_breakpoint = this.editor.current_breakpoint
        // remove from inherited if current_breakpoint its being updated
        if(inherited_breakpoints.includes(current_breakpoint)){
            inherited_breakpoints.splice(inherited_breakpoints.indexOf(current_breakpoint), 1)
        }
        for(let breakpoint of Object.keys(current_breakpoints)){
            if(!this.layouts.hasOwnProperty(breakpoint) || inherited_breakpoints.includes(breakpoint)){
                // Copy from default layout(breakpoint) to all inherited or empty breakpoints
                this.layouts[breakpoint] = this.layouts['default']
                let child_values = []
                for(let field of Object.values(this.layouts['default'])){
                    let new_field = {...field}
                    new_field.w = Math.min(new_field.w, this.cols[breakpoint])
                    child_values.push(new_field)
                }
                this.layouts[breakpoint] = child_values
                if(!inherited_breakpoints.includes(breakpoint)){
                    inherited_breakpoints.push(breakpoint)
                }
                
            }

        }
        this.set_inherited_breakpoints(inherited_breakpoints)
        
        return {new_layouts:this.layouts, inherited_breakpoints}
    }

    merge_current_breakpoint(layouts){
        const current_breakpoint = this.editor.current_breakpoint
        if(layouts[current_breakpoint] && this.layouts[current_breakpoint]){
            let new_layout = layouts[current_breakpoint]
            this.layouts[current_breakpoint].forEach(function(field){
                if(!new_layout.find(f => f.i === field.i)){
                    new_layout.push(field)
                }
            })
        }
        return layouts
    }

    async save_view_layout(){
        let layouts = this.merge_current_breakpoint(this.layouts)
        this.complete_layout_breakpoints(layouts)
        // const new_layout = {...this.layout, ...{'layouts':new_layouts, 'inherited_breakpoints':inherited_breakpoints}}
        let value = JSON.stringify(this.definition)
        if(this.save_layout){
            await this.save_layout(value)
        }
        else{
            const abortController = new AbortController();
            let args = { 
                view_id: this.screen.id,
                layout:JSON.stringify(this.definition)
            }
            await this.screen.connection.dispatch('POST', '/view_handler/save_view_layout', args, false, false, false, abortController)
        }
        if(this.screen.sub_screens){
            Object.values(this.screen.sub_screens).forEach(function(sub_screen){
                if(sub_screen.view_layout){
                    sub_screen.view_layout.save_view_layout()
                }
            })
        }
        this.set_changed(false)
        this.editor.remove_draft_operation('layout_change')
        this.screen.connection.notifications.addSnack(
            { message: "Vista Actualizada",  persistant:false }
        )

    }

    // Focus Management

    set_focused_element(element_name){
        if(element_name == this.focused_element){
            return
        }
        const focused_field = this.screen.currently_visible_fields
                                    .find(f => f.original_name === this.focused_element)
        if(focused_field){
            focused_field.set_focus(false)
        }

        this.focused_element = element_name
        const new_focused_field = this.screen.currently_visible_fields
                                    .find(f => f.original_name === element_name)

        
        if(new_focused_field){
            new_focused_field.set_focus(true)
        }

    }
    focus_next_element(){
        

        this.set_focused_element(this.next_visible_element)
        
        
    }


}
   

    