import React, {createRef, forwardRef} from 'react';
import Field from '../../ui/fields/Field';
import Container from '../../ui/surfaces/Container'
import { observer } from 'mobx-react-lite'
import GridHoc from '../../ui/GridHoc.js'
import { FormField } from '../form/NewFormView.js';

const FieldValue = observer(forwardRef(function FieldValue(props, ref) {

    const { field, record, edition_mode } = props;

    return (
        <div 
            ref={ref} 
            className={"text-xs text-gray-600 bg-green-300 font-medium"} 
            {...props} 
            key={field.name}
            onContextMenu={function (e) {
                e.preventDefault()
                field.open_context_menu(e.currentTarget, record)
                e.stopPropagation()

            }}
            >
            <Field
                    field={field}
                    record={record}
                    variant="outlined"
                    // ref={ref}
                    edition_mode={edition_mode}
                    // noWrap
                    // content_only

                />
            {props.children}
        </div>
    )
}));


const ListCardItem = observer(function Cell(props) {

    const { screen, record, layoutChangeCallback } = props;
    const fields = screen.visible_fields
    const edition_mode = screen.editor ? screen.editor.design_mode:false
    const visible_fields = edition_mode ? fields:screen.get_visible_fields(record)
    



    return (
        <div onClick={(e)=>{
            screen.set_selected_record([record])
            }}>

        <Container type="paper" className="border border-gray-300" shadowClass="shadow-md">
            {fields && record ?
                <GridHoc 
                    screen={screen} 
                    rowHeight={5}
                    layoutChangeCallback={layoutChangeCallback}
                    >

                    {visible_fields.map((field, index) => (
                                //     <FieldValue
                                //         edition_mode={edition_mode}
                                //         ref={createRef()}
                                //         key={field.name}
                                //         field={field}
                                //         record={record}
                                // />
                                <FormField
                                    key={field.original_name}
                                    edition_mode={edition_mode}
                                    field={field}
                                    readonly={field.readonly}
                                    record={record}
                                    ref={createRef()}/>


                                ))}
                </GridHoc>
                :
                ""
            }

        </Container>
        </div>


    )


});

export default ListCardItem;