import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite'
import Filter from './Filter'

const QuickFilter = observer(function QuickFilter(props) {
    const { filter, screen, hideLabel } = props;
    const record = screen.filters.record

    return (
        <div 
            className='w-full' 
            onContextMenu={function (e) {
                e.preventDefault()
                filter.open_context_menu(e.currentTarget)
                e.stopPropagation()
            }}
            key={filter.filter_id}>

            {/* <div className="w-1/3"> */}
                <Filter
                    filter={filter}
                    removeFilter={() => { }}
                    record={record}
                    // containerStyle={{ 'padding': 0 }}
                    fieldWrapperStyle={{paddingLeft:0}}
                    hideLabel={hideLabel}

                />
            {/* </div> */}


        </div>



    )

});

export default QuickFilter;