import React, { createRef, Fragment } from 'react';
import { observer } from 'mobx-react-lite'
import { FormField } from '../../views/form/NewFormView.js'
import ViewEditionToolbar from '../../views/ViewEditionToolbar.js';
import { ReactComponent as DownIcon } from '../icons/svg/chevron-down.svg';
import { ReactComponent as UpIcon } from '../icons/svg/chevron-up.svg';
import IconButton from '../buttons/IconButton.js';
import GridHoc from '../GridHoc.js';
import { computed } from 'mobx'

const Group = observer(function Group(props) {

    const { field, record } = props
    const { collapsible, collapsed, sub_screen, show_completion_percentage } = field
    const style = field.get_style(record)
    const editor = sub_screen.editor
    const editor_actions = editor.edition_options
    const edition_mode = editor ? (editor.design_mode || sub_screen.main_screen_design_mode) : false

    const visible_fields = edition_mode ? sub_screen.visible_fields : sub_screen.get_visible_fields()
    const visible_fnames = computed(() => visible_fields.map(
                                                            function(f){return f.original_name}
                                                            ).toString()
                                                            ).get()

    const children = React.useMemo(() => {
        return visible_fields.map((field, index) => {
          return  <FormField
                    key={field.original_name}
                    edition_mode={edition_mode}
                    field={field}
                    readonly={field.readonly}
                    record={record}
                    ref={createRef()}
            />

  
        });
      }, [visible_fnames]);

    return (
        <div
            className='w-full flex flex-col h-full overflow-auto'
        >
            <div
                className="bg-light-selected text-gray-800 text-sm font-medium rounded px-2 py-2 w-full flex flex-row"
                style={field.label_style}
            >
                <div
                    className='w-full flex flex-row justify-between'

                >
                    {field.description}
                    {show_completion_percentage &&
                        <>
                            {field.completion_percentage}
                        </>
                    }

                    {
                        collapsible ?
                            <IconButton
                                onClick={(e) => { field.collapse(record) }}
                                color="transparent"
                                size="md"
                                style={{ color: 'inherit' }}
                                shape="circle"
                                title="Info">
                                {
                                    collapsed ?
                                        <DownIcon
                                            className="fill-current"
                                        />
                                        :
                                        <UpIcon
                                            className="fill-current"
                                        />

                                }

                            </IconButton>
                            :
                            ""
                    }
                </div>
                {edition_mode &&
                    <div className="flex flex-row w-full justify-end">

                        <ViewEditionToolbar
                            screen={sub_screen}
                            hide_edition_buttons={true}
                            hide_extra_options={true}
                            container_classname='mb-2 sticky top-0'
                        />

                    </div>
                }

            </div>
            {!collapsed &&
                <div
                    style={{ display: props.invisible ? 'none' : '', ...style }}
                    className="w-full flex flex-col h-full overflow-auto"
                >

                    <div
                        className="w-full">
                        <GridHoc
                            screen={sub_screen}
                            rowHeight={42}
                            onDragStart={(a, b, c, d, event) => event.stopPropagation()}
                            isDroppable={true}

                        >
                            {children}
                        </GridHoc>
                    </div>


                </div>
            }

        </div>


    )

});

export default Group;