import { action, makeObservable, observable } from "mobx"
import { environment } from "../common"
class EditorConfig {
    initialized = false
    view_definitions = {}
    view_wizards = []
    
    constructor(connection) {
        
        this.connection = connection
        makeObservable(this,{
            view_definitions:observable,
            initialized:observable,
            view_wizards:observable,
            set_initialized:action,
            set_view_definitions:action,
            
        })   
        this.initialize()  

    }

    async initialize(){
        await this.loadViewDefinitions()
        await this.loadViewWizards()
        this.set_initialized(true)
        
    }
    set_initialized(val){
        this.initialized = val;
    }
    set_view_definitions(definitions){
        this.view_definitions = definitions;
    }
    set_view_wizards(wizards){
        this.view_wizards=wizards
    }

    async loadViewDefinitions(){
        const abortController = new AbortController();
        let args = {
            'environment':environment()
        }
        let definitions = await this.connection.dispatch('GET', '/view_handler/editor_definitions', args, false, true, false, abortController)
        this.set_view_definitions(definitions)
        
    }
    async loadViewWizards(){
        const abortController = new AbortController();
        let args = {
            'environment':environment()
        }
        let wizards = await this.connection.dispatch('GET', '/view_handler/view_wizards', args, false, true, false, abortController)
        this.set_view_wizards(wizards)

    }

    // type: one of ['fields','view']
    get_definition(type){
        let definition = false
        if(this.view_definitions[type]){
            definition = this.view_definitions[type]
        }
        return definition
    }



}

export default EditorConfig;