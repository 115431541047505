import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from './Screen';
import { BoardScreen } from './board/BoardScreen';
import {HTMLScreen} from './html/HtmlScreen';
import {SpreadsheetScreen} from './list/SpreadsheetScreen'
import {GraphScreen} from './graph/GraphScreen'
import { CalendarScreen } from "./calendar/CalendarScreen";
import { FlowChartScreen } from "./flowchart/FlowChartScreen";
import { FormScreen } from "./form/Form";



export class ScreenGroup {
    screens = {}
    connection = false
    active_screen = false
    context_menu_handler = false
    widgets_registry = {}


    constructor(connection, sub_group=false, context_menu_handler=false, widgets_registry={}) {
        makeAutoObservable(this, {
            addScreen: action,
            removeScreen: action,
            clear: action,
        }
        )
        this.connection = connection
        this.screens = {}
        this.active_screen = false
        this.sub_group = sub_group
        this.context_menu_handler = context_menu_handler
        this.widgets_registry = widgets_registry
        
        if (this.sub_group==false){
            
            this.connection.setMainScreenGroup(this)
        }
        


    }

    set_active_screen(screen) {
        runInAction(() => {

            if (this.active_screen) {
                for (let id in this.active_screen.field_childs.screens) {
                    this.active_screen.field_childs.removeScreen(id)
                }
            }


            this.active_screen = screen
        })
    }

    // initialize_offline_screens(){
    //     console.log("initialize_offline_screens:OFFLINE AVAILABLE ROUTES")
    //     console.log(this.connection.offline_routes)
    // }

    addScreen(view, 
            history, 
            initial_search, 
            route_state = {}, 
            set_active = true, 
            is_modal = false, 
            parent = false, 
            initialize_fields = true, 
            initialize_actions = true, 
            initialize_data=true, 
            initialize_callback=false, 
            fileHandler=false, 
            data_callback=false,
            context
            ) {
        console.log("ADDING SCREEN")
        let res = ""
        // this is not stable enough, cause the device could go offline just before this screen is added and you dont know it.
        let offline_mode = this.connection.offline_mode
        if(offline_mode){
            if(!this.connection.offlineAvailable(view.id)){
                return false
            }
        }
        // Dynamic o2m provides a custom view id, avoiding to crash if there are two child screens targeting same view
        const screen_id = view.custom_id ? view.custom_id:view.id
        const setScreenTitle = (screen)=>{
            let title = route_state[initial_search.action_id] ? route_state[initial_search.action_id].title : screen.view.title
            
            screen.set_title(title)
        }
        // TODO: Improve Multi model reload: currently if the screen is multi_model, the whole screen gets recreated. 
        if (!this.screens[view.id] || view.multi_model_view || view.pivot_table) {
            let screen
            if (SCREEN_TYPES[view.type]) {
                screen = new SCREEN_TYPES[view.type](this, view, this.connection, history, initial_search, route_state, is_modal, parent, initialize_fields, initialize_actions, initialize_data, initialize_callback, fileHandler, data_callback=data_callback, false,context)
            }
            else {
                screen = new Screen(this, view, this.connection, history, initial_search, route_state, is_modal, parent, initialize_fields, initialize_actions, initialize_data, initialize_callback, fileHandler, data_callback=data_callback, false,context)
            }
            this.screens[screen_id] = screen    
            
            
            res = screen
            setScreenTitle(res)
            

        }
        else {
            res = this.screens[screen_id]
            if(context){
                res.set_context(context)
            }
            //TODO => Refetching data each time the screen is mounted. Connect to stale or parameters.

            setScreenTitle(res)

            if (res.start_empty) {

                if (initial_search.current_search.length) {
                    res.do_search(initial_search)
                }

            }
            else {
                console.log("CALLING DO SEARCH FROM ADD SCREEN")
                res.do_search(initial_search)
            }

        }
        if (set_active) {
            this.set_active_screen(res)
        }



        return this.screens[screen_id]



    }

    removeScreen(view_id) {
        delete this.screens[view_id]
    }

    clear() {
        this.screens = {}
    }

    getScreen(id) {
        return this.screens.filter(function (view) { return view.id === id })[0]
    }
    createGroup(connection, sub_group=false, context_menu_handler=false, widgets_registry=false){
        if(!context_menu_handler){
            context_menu_handler = this.context_menu_handler
        }
        if(!widgets_registry){
            widgets_registry = this.widgets_registry
        }
        return new ScreenGroup(connection, sub_group, context_menu_handler, widgets_registry)
    }
}

const SCREEN_TYPES = {
    'board': BoardScreen,
    'html': HTMLScreen,
    'list': SpreadsheetScreen,
    'spreadsheet': SpreadsheetScreen,
    'flowchart': FlowChartScreen,
    'graph':GraphScreen,
    'calendar':CalendarScreen,
    'form':FormScreen
}