import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from '../Screen'


export class FormScreen extends Screen {

    get visible_fields(){
        const vf = this.fields.filter(field => field.visible)
        //needs review
        if (vf[0] !== "selector" && this.type === 'list' || this.type === 'spreadsheet') {
            vf.unshift("selector")
        }
        
        return vf.filter(field => !field.is_grouped)
        // Super calls fails on getters (on production bundle)
    }
    get grouped_visible_fields(){
        return this.fields.filter(field => field.visible).filter(field => field.is_grouped)
    }

    constructor(
        group, 
        view, 
        connection, 
        navigate, 
        initial_search, 
        route_state, 
        is_modal, 
        parent, 
        initialize_fields = true, 
        initialize_actions = true, 
        initialize_data=true, 
        initialize_callback=false, 
        fileHandler=false,
        data_callback=false,
        on_load_data=false,
        context={},
        ) {
        
        super(group, 
            view, 
            connection, 
            navigate, 
            initial_search, 
            route_state, 
            is_modal, 
            parent, 
            initialize_fields, 
            initialize_actions, 
            initialize_data, 
            initialize_callback, 
            fileHandler,
            data_callback,
            on_load_data,
            context)

       

    }
    
    


}