import {Action} from '../../model/Action'
import { v4 as uuidv4 } from 'uuid';
import { makeObservable,observable, action,runInAction, toJS } from 'mobx';
import { environment } from '../../common'
import { embedDashboard } from "@superset-ui/embedded-sdk";
class BoardWidget {
    type = ""
    id = ""
    name = ""
    colspan = false
    height = ""
    search_connected = false
    filters_map = {}

    get current_search(){
        if(!this.search_connected){
            return {}
        }
        let parent_search = this.parent.editable_search_state
        parent_search['current_search'] = parent_search.current_search.map(function(term){

            if(!this.filters_map.hasOwnProperty(term[0])){
                return false
            }
            term[0] = this.filters_map[term[0]]
            

            return term
        }.bind(this)).filter(domain => domain!==false)

        return parent_search

    }
    get original_name(){
        return this.id.toString()
    }

    constructor(attributes,parent){
        this.type = attributes.type
        this.name = attributes.name
        this.parent = parent
        this.id = attributes.id
        this.search_connected = attributes.search_connected
        this.colspan = attributes.colspan ? parseInt(attributes.colspan):false
        this.height = attributes.height ? parseFloat(attributes.height): 0.4
        this.filters_map = attributes.filters || {}
        this.default_colspan = 4
        this.default_height = 20

    }

    edit_widget(){
        return this.parent.editor.openBoardWidgetScreen(this.id)
        

    }

    open_context_menu(element){
        this.parent.group.context_menu_handler.open(this, element, null, this.name)
    }

    get_edition_context_menu(){
        return [
            {
                'label':'Edit',
                'action':()=>{
                    this.edit_widget()
                },
                
            },
            {
                'label':'Delete',
                'action':()=>{
                    this.parent.deleteWidget([this.id])
                },
                
            },
            
        ]
    }

    get_context_menu_options(record, screen=false){
        screen = screen ? screen:this.parent
        if(screen.editor.editable_widget() && screen.editor.design_mode){
            return this.get_edition_context_menu()
        }
        return []
        
    }

}

class ViewWidget extends BoardWidget{
    view_definition = ""
    screen = ""

    constructor(attributes,parent){
        super(attributes,parent)
        this.view_definition = attributes.widget_view
        this.view_definition.start_empty = true;
        this.parent = parent
        const view_definition = {...this.view_definition, 'custom_id':this.id}
        this.screen = this.parent.child_screens.addScreen(view_definition, 
            false, 
            { current_search: [] }, 
            {}, 
            false, 
            false, 
            false, 
            true, 
            true, 
            true, 
            false, 
            false, 
            false,
            {'board_widget_id':this.id}
            )
        // if(this.search_connected){
        //     this.screen.filterable = false;
        // }
        
        this.screen.parent = this.parent
        this.screen.set_title(this.name)
        this.colspan = this.colspan ? this.colspan:4
        this.screen.navigate = false;
    }
}


class IndicatorWidget{
    constructor(attributes,screen){

    }
}
class ActionWidget extends BoardWidget{
    action = ""
    image = ""
    icon_name = ""
    text_color = ""
    icon_color = ""
    background_color = ""
    
    constructor(attributes,parent){
        super(attributes,parent)
        if(attributes['action']){
            this.action = new Action(attributes['action'], parent)
        }
        this.icon_name = attributes['icon_name']
        this.text_color = attributes['text_color']
        this.icon_color = attributes['icon_color']
        this.background_color = attributes['background_color']
        this.colspan = this.colspan ? this.colspan:2
        makeObservable(this,{
            image:observable,
            icon_name:observable,
            getImage:action,
        })
        this.getImage()
        
    }

    async getImage(){
        let args = {
            'widget_id':this.id
        }
        const abortController = new AbortController();
        let image = await this.parent.connection.dispatch('GET', '/widget_image', args, false, true, true, abortController)
        if (environment() === 'web') {
            image = URL.createObjectURL(image)
        }
        else {
            var reader = new FileReader();
            reader.readAsDataURL(image.data);
            reader.onloadend = function () {
                var base64data = reader.result;
                image = base64data
            }

        }
        runInAction(() => {
            this.image = image
            
            


        })
        
    }
}

class SupersetDashboardWidget extends BoardWidget{
    uuid = false
    guest_token = ""
    constructor(attributes, parent){
        super(attributes, parent)
        this.uuid = attributes.superset_dashboard_uuid
        this.guest_token = ""
        makeObservable(this,{
            guest_token:observable,
            set_guest_token:action,
        })
    }
    
    isEnabled(){
        return this.parent.connection.superset.enabled
    }

    set_guest_token(token){
        this.guest_token = token
    }
    async getGuestToken(){
        let args = {}
        if(this.guest_token){
            return this.guest_token
        }
        const abortController = new AbortController();
        let guest_token = await this.parent.connection.dispatch('GET', '/superset/guest_token', args, false, false, false, abortController)
        this.set_guest_token(guest_token)
        return guest_token
    }

    async  embedSupersetDashboard(element, style){
        const res = await embedDashboard({
            id: this.uuid,
            supersetDomain:this.parent.connection.superset.url,
            mountPoint: element, // any html element that can contain an iframe
            fetchGuestToken: () => this.getGuestToken(),
            dashboardUiConfig: { hideChartControls:true }, // dashboard UI config: hideTitle, hideTab, hideChartControls (optional)
          });
        
        
    }
}

export const BoardWidgets = {
    'view': ViewWidget,
    'indicator':IndicatorWidget,
    'action':ActionWidget,
    'superset_dashboard':SupersetDashboardWidget,
}
