import React, { useState, useEffect, useRef } from 'react';

import 'react-virtualized/styles.css';

import Container from '../../ui/surfaces/Container.js'
import { List, AutoSizer, Grid, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import clsx from 'clsx';
import scrollbarSize from 'dom-helpers/scrollbarSize';

import ViewHeader from '../ViewHeader.js';
import LoadingIndicator from '../../common/LoadingIndicator'
import ListCardItem from './ListCardItem';
import ViewEditionToolbar from '../ViewEditionToolbar.js';
import { observer } from 'mobx-react-lite'


const cache = new CellMeasurerCache({
    defaultHeight: 50,
    fixedWidth: true,
    keyMapper: () => 1,
});

const CardList = observer(function CardList(props) {
    const screen = props.screen
    const [selectedIndex, setSelectedIndex] = useState({})
    const height = !screen.data.records.length ? 50:props.height ? props.height : (window.innerHeight * 75) / 100;
    const overscanColumnCount = 0;
    const overscanRowCount = 10;
    const rowHeight = 600;
    const rowCount = screen.data.records.length
    const selected_records = screen.selected_records
    const container_ref = React.createRef()
    const data = screen.data.records

    function _rowRenderer({ index, isScrolling, parent, key, style }) {

        const record = data[index];


        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ registerChild, measure }) => (
                    <div key={key} ref={registerChild} style={style} className="px-2 py-4">

                    {/* <div> */}
                    
                        
                        
                            <ListCardItem 
                                record={record} 
                                layoutChangeCallback={measure}
                                screen={screen} />
                        
    
                    {/* </div> */}
    
                </div>
                )}
            </CellMeasurer>

        );
    }






    return (
        <div ref={container_ref} className=" flex justify-center  bg-white">
            <div className="w-full h-full">
                <LoadingIndicator
                    ref={container_ref}
                    show={screen.data.loading}
                // show={true}
                // close={()=>{}}
                />
                 <ViewEditionToolbar
                        screen={screen}
                        container_classname='mb-2 sticky top-0'
                    />
                {(!props.headless) &&
                    <div className="px-2">
                        <ViewHeader
                            screen={screen}
                            hide_toolbar={props.hide_toolbar}
                            isSpreadsheet={false}

                            {...props.header_props}
                        />
                    </div>
                }

                <div style={{'flex':'1 1 auto'}}>
                    <AutoSizer disableHeight  className="mt-2 bg-red-300" >
                        {({ width }) => (
                            <List
                                ref="List"
                                // className={styles.List}
                                height={height}
                                overscanRowCount={overscanRowCount}
                                // noRowsRenderer={this._noRowsRenderer}
                                rowCount={rowCount}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={_rowRenderer}
                                width={width}
                            />
                        )}
                    </AutoSizer>
                </div>
            </div>




        </div>


    )

}
)


export default CardList;