import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import 'react-virtualized/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './calendar_styles.css';

import { ReactComponent as ChevronLeft } from '../../ui/icons/svg/cheveron-left.svg';
import { ReactComponent as ChevronRight } from '../../ui/icons/svg/cheveron-right.svg';

import { observer } from 'mobx-react-lite'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import IconButton from '../../ui/buttons/IconButton';


const customSelectStyles = {
    menu: (provided, state) => ({
        ...provided,
        borderRadius: '0px',
        marginTop: '0px'
    }),

    control: (provided, state) => ({

        ...provided,
        border: '0px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        // padding: '0px',
        minHeight: '0px',


    }),

    indicatorSeparator: (provided, state) => ({

        ...provided,
        display: 'none',




    }),
    dropdownIndicator: (provided, state) => ({

        ...provided,
        // display: 'none',
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',
        // width: '0px',
        color: 'inherit',
        // ':hover': {

        //     display: 'flex',
        // },



    }),
    input: (provided, state) => ({

        ...provided,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',



    }),
    valueContainer: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',




    }),
    singleValue: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        padding: 0,
        maxHeight: '1.5rem',
        fontSize: 'inherit',
        fontWeight: 'inherit',

        color: 'inherit',
        fontFamily: 'inherit',




    }),
    menu: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        fontSize: '0.75rem',
        fontWeight: '500',
        color: 'rgba(113, 128, 150,1)',
        fontFamily: 'inherit',
        zIndex: 9999


    }),
    option: (provided, state) => ({

        ...provided,
        cursor: 'pointer',


    }),

    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 9999
    }),



}



const CalendarView = observer(function CalendarView(props) {
    const {screen} = props
    const data = screen.data.records
    const locale = screen.locale
    moment.locale(locale.lang)
    const localizer = momentLocalizer(moment)
    const height = props.height ? props.height : (window.innerHeight * 90) / 100;
    const view_types = screen.view_types
    const [viewSelected, setViewSelected] = useState('Mes')
    

    const filtersInCalendar = true;

    const ref = React.createRef();

    function onRangeChange(range){
        let nameView = range;

        range === 'month' ? nameView = 'Mes': 
        range === 'week' ?  nameView = 'Semana':
        range === 'day' ?  nameView = 'Dia':
        range === 'agenda' ? nameView = 'Agenda':
        range === 'work_week' ?  nameView = 'Semana Laboral':
        nameView = 'undefined'

        setViewSelected(nameView)
    }
   
    const Toolbar = (props)=>{

        const optionView = props.views.map( view => {
            return {
            value: view, 
            label: view === 'month' ? 'Mes': 
            view === 'week' ? 'Semana':
            view === 'day' ? 'Dia':
            view === 'agenda' ? 'Agenda':
            view === 'work_week' ? 'Semana Laboral':
            'undefined'  } })

        const selectView = (view) =>{
            props.onView(view)
            //console.log(view); 
        }

        const goToPrevious = () => {
            props.onNavigate('PREV');
        };
        const goToNext = () => {
            props.onNavigate('NEXT');
        };
   
        return(
            <div className='w-full'>

                <div className='flex text-s text-gray-800 '>

                    <div className=' flex w-2/5 pt-1'>
                    <p className=" mx-2 mr-2 text-md text-left flex items-center  text-gray-600 font-medium " >{screen.title}</p>
                    </div>
                    <div ref={ref} className='flex w-3/5  justify-between'>
                        <div className='flex  justify-center py-3'>
                            <IconButton onClick={goToPrevious} className="text-primary" color="transparent">
                                <ChevronLeft style={{ width: '.8em', height: '.8em' }} className="fill-current text-primary text-2xl cursor-pointer rounded" />
                            </IconButton>
                            <p className='flex w-48 text-m justify-center items-center'>{props.label}</p>    
                            <IconButton onClick={goToNext} color="transparent">
                                <ChevronRight style={{ width: '0.8em', height: '0.8em' }} 
                                className="fill-current text-primary text-2xl cursor-pointer rounded" />
                            </IconButton>
                        </div>
                                               
                        <div className='flex py-3'>
                            <div className='flex items-center'>
                                <div className="hover:text-primary w-40 text-gray-600  text-xs font-medium" >
                                    <Select
                                        styles = {customSelectStyles}
                                        placeholder = {viewSelected}
                                        options = { optionView }
                                        onChange = {(value) => selectView(value.value)}
                                        getOptionValue = { option => option.value }
                                        getOptionLabel = { option => option.label }
                                    />
                                </div>
                            </div>

                            {/* {
                                screen.filterable ?
                                <IconButton color="transparent" className="text-primary">
                                    <FilterBar 
                                        style={{ width: '.8em', height: '.8em' }} 
                                        className="fill-current text-xl cursor-pointer" 
                                        screen={screen} 
                                        filtersInCalendar={screen.filters.active_filters.length > 0 ? filtersInCalendar : false}  
                                        parent_ref={ref}
                                    />         
                                </IconButton>    
                                :
                                    ""
                            }  */}

                        </div>
                    </div>
                </div> 
            </div>

        )
    }   
        
    return (
            <div style={{height}} className=" w-full bg-white ">

            <Calendar
                selectable
                popup
                events={data}
                views={view_types}
                step={60}
                showMultiDayTimes
                titleAccessor={(event) => {return screen.get_title_value(event)}}
                startAccessor={(event) => {return screen.get_start_date_value(event)}}
                endAccessor={(event) => {return screen.get_end_date_value(event)}}
                allDayAccessor={(event) => {return screen.all_day_events}}
                onSelectEvent={(event) => {screen.view_event_detail([event])}}
                onSelectSlot={(info)=> {screen.new_event(info)}}
                localizer={localizer}
                onView={onRangeChange}
                messages={{
                    'month':'Mesesesssssss',
                    'week':'Semana',
                    'day' : 'Dia',
                    'agenda': 'Agenda',
                    'work_week': 'Semana de trabajo',
                    'today': 'Hoy',
                    'previous': '<',
                    'next': '>',
                }}
                timeslots={1}
                min={new Date(0, 0, 0, 10, 0, 0)}
                max={new Date(0, 0, 0, 22, 0, 0)}
                components={{
                    toolbar: Toolbar,
                }}
                formats={{
                    dayFormat: 'dd MM YYYY',
                    timeGutterFormat: 'ha',
                    dayHeaderFormat: 'DD MMMM, YYYY',
                }}
                defaultDate={new Date()}
                defaultView="month"
            />
            </div>
    )
}
)


export default CalendarView;