import { getSelectionOption } from './common'

export const operators = [
    { label: '=', value: '=' },
    { label: 'not', value: '!=' },
    { label: 'Includes', value: 'in' },
    { label: 'Not Includes', value: 'not in' },
    { label: '>', value: '>' },
    { label: '>=', value: '>=' },
    { label: '<', value: '<' },
    { label: '<=', value: '<=' },
    { label: 'Contain', value: 'ilike' },

]

export const getOperator = (value) => {
    const ops = operators.filter(function (op) {
        return op.value === value
    })
    if (ops[0]) {
        return ops[0]
    }
    else {
        throw new Error("Invalid Operator: " + value);
    }
}

export const typeOperators = {
    'number': ['=', '>', '<'],
    'char': ['=', 'ilike'],
    'selection': ['in', 'not in'],
    'date': ['=', '>', '<'],
    'boolean':['=','!=']
}

export const typeMapping = {
    'char': 'char',
    'selection': 'selection',
    'many2one': 'selection',
    'multi_selection': 'selection',
    'multi_link': 'selection',
    'float': 'number',
    'integer': 'number',
    'date': 'date',
    'datetime': 'date',
    'boolean':'boolean'
}

export const BOOLEAN_OPTIONS = [
    {'value':'true', 'label':"True"},
    
]

export const getDefaultOperator = (type) => {
    switch (type) {
        case 'selection': return 'in'
        case 'multi_selection': return 'in'
        case 'multi_link': return 'in'
        case 'date': return '='
        case 'datetime': return '='
        case 'integer': return '='
        case 'many2one': return 'in'
        case 'boolean': return '='
        default: return 'ilike'
    }
}

//search: [] => current_search of type [[field,operator,value], [field,operator,value]]
//fields: [] => array of field definitions
//filters: [] => array of filters (used to get the raw values on async fields)
export const getSearchString = (search, fields, filters) => {
    let filter_string = ""
    if (!search.length || !fields.length) {
        return filter_string
    }
    
    const getField = (fname) => {
        return fields.filter(function (field) {
            return field.name === fname
        })[0]

    }
    const getFieldValue = (field, value,filter) => {
        if(!field){
            return false
        }
        switch (field.type) {
            case 'selection':
                return '['.concat(value.map(function (val) {
                    return getSelectionOption(val, field)
                })).concat(']')
            case 'many2one':
                if (filter) {
                    const data_field = field.name.concat('.')
                    
                    const getRecName = (v) => {
                        if (filter.raw_values.values[field.name]) {

                            if (filter.raw_values.values[data_field] && Array.isArray(filter.raw_values.values[data_field])) {

                                const filtered = filter.raw_values.values[data_field].filter(function (data_value) {

                                    return data_value.id === v
                                })
                                if(filtered.length){
                                    return filtered[0].rec_name
                                }
                                else{return ""}


                            }
                        }
                    }
                    return '['.concat(value.map(function (val) {
                        
                        return getRecName(val)
                        
                        
                    })).concat(']')
                }
                else {
                    return value
                }

            default: return value
        }
    }
    search.forEach(function (pair) {
        const field = getField(pair[0])
        if(!field){
            return ""
        }
        const operator = getOperator(pair[1]).label
        let filter = false;
        if(filters){
            filter = filters.filter(function(f){return f.field.name===field.name})[0]
        }
        
        const value = getFieldValue(field, pair[2], filter)
        
        filter_string = filter_string.concat(field.description).concat(' ').concat(operator).concat(' ').concat(value.toString()).concat(', ')

    })

   
    return filter_string
}

const search = {
    operators,
    typeOperators,
    typeMapping,
    getDefaultOperator,
    getOperator,
    getSearchString,
    BOOLEAN_OPTIONS
}


export default search;