import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { ScreenLayout } from "./ScreenLayout"
import { ScreenEditor } from "./ScreenEditor"

// Used for form group (layouts)
export class SubScreen{
    main_screen = false
    parent_object = false
    view_layout = {}
    
    get visible_fields(){
        return this.parent_object.visible_fields
    }
    get currently_visible_fields(){
        return this.visible_fields.filter(field => field.is_visible(this.active_record))
    }

    get connection(){
        return this.main_screen.connection
    }

    get main_screen_design_mode(){
        return this.main_screen.editor && this.main_screen.editor.design_mode ? true:false
    }

    get active_record(){
        return this.main_screen.active_record
    }

    constructor(
                main_screen,
                parent_object, //oneOf [field]
                view_layout,
              
                ) {
            
            this.id = parent_object.id
            this.main_screen = main_screen
            this.parent_object = parent_object
            this.editor = new ScreenEditor(
                                            this.main_screen.connection.editor_config,
                                            this, 
                                            this.main_screen.view.view_edition_access,
                                            this.main_screen.editor
                                            )
            this.view_layout =  new ScreenLayout(this, view_layout, (values) =>{return this.save_view_layout(values)})

            makeObservable(this, {
                parent_object: observable,
                currently_visible_fields:computed,
                
    
            })
            
    }

    get_visible_fields(){

        return this.visible_fields.filter(field => field.is_visible(this.active_record))
    }
    async save_view_layout(values){
        return await this.parent_object.save_layout(values)
    }

    


}