import { action, makeObservable, observable, computed } from "mobx"

export class ScreenEditor {
    permissions = {}
    screen = {}
    design_mode_ = false
    current_breakpoint = 'default'
    draft_operations = {}

    get fields_permission(){
        return this.permissions.new_field
    }

    get view_edition_permission(){
        return this.permissions.view_edition
    }

    get edition_buttons(){
        const options = []
        const type = this.screen.type
        const filterable = this.screen.filterable
        if(this.view_edition_permission){
            
            if(filterable && type != 'form'){
                options.push({'id':'new_filter','name':'+ Add Filter', 'callback':()=>{this.openFilterScreen()}})
            }
            if(type == 'board'){
                options.push({'id':'new_board_widget','name':'+ Add Widget', 'callback':()=>{this.openBoardWidgetScreen()}})
                
            }
            else{
                options.push({'id':'new_field','name':'+ Add Field', 'callback':()=>{this.openFieldScreen()}})
                // options.push({'id':'new_filter','name':'+ Add Filter', 'callback':()=>{this.openFilterScreen()}})
                options.push({'id':'edit_actions','name':'Actions', 'callback':()=>{this.openActionsScreen()}})
            }
            
        }

        return options
    }

    get edition_options(){
        const options = []
        const type = this.screen.type
        // if(this.fields_permission){
        //     options.push({'id':'add_field','label':'+ Añadir Campo', 'onClick':()=>{this.openFieldScreen()}})
        // }
        if(this.view_edition_permission){
            options.push({'id':'edit_view','label':'Edit View', 'onClick':()=>{this.openViewScreen()}})
        }

        return options
    }

    get design_mode(){
        if(this.main_editor){
            return this.main_editor.design_mode
        }
        return this.design_mode_
    }
    
    constructor(config, screen, permissions={}, main_editor=false) {
        this.config = config;
        this.screen = screen;
        this.permissions = permissions || {};
        this.draft_operations = {}
        this.connection = screen.connection
        this.main_editor = main_editor
        
        makeObservable(this,{
            permissions:observable,
            config:observable,
            design_mode_:observable,
            toogle_design_mode:action,
            current_breakpoint:observable,
            set_current_breakpoint:action,
            draft_operations:observable,
            add_draft_operation:action,
            remove_draft_operation:action,
            edition_buttons:computed

        })   
       

    }

    toogle_design_mode(){
        
        this.design_mode_ = !this.design_mode_
        if(this.screen.use_view_layout){
            if(!this.design_mode_){
                this.screen.view_layout.changes_initialized = false
            }
            else{
                this.screen.fields.forEach(function(field){
                    if(field.collapsed){
                        field.collapse()
                    }
                })
            }
        }
        
        // if(this.screen.sub_screens){
        //     Object.values(this.screen.sub_screens).forEach(function(sub_screen){
        //         if(sub_screen.editor){
        //             sub_screen.editor.toogle_design_mode()
        //         }
        //     })
        // }
        
    }
    set_current_breakpoint(current_breakpoint){
        this.current_breakpoint = current_breakpoint
    }

    async executeAndReload(record,screen){
        await screen.default_save_action.execute([record],false,false,true)
        console.log("RUNNING EXECUTE AND RELOAD (FIELDS ONLY)")
        await this.screen.loadFields({},{},true,{'reset_cache':true})
        this.screen.reinitialize_field_childs()
        
    }



    editable_field(){
        return (this.screen.editable_view && this.config.get_definition('new_field'))
    }
    editable_widget(){
        return (this.screen.editable_view && this.config.get_definition('board_widget_edit_form'))
    }



    openFieldScreen(field_id=null){
        if(!this.screen.editable_view){
            return
        }
        const view_definition = this.config.get_definition('new_field')
        if(!view_definition){
            return
        }
        let search = {}
        if(field_id){
            search = {'current_search':[['id','=',field_id]]}
        }
        const field_screen = this.screen.modal_childs.addScreen(view_definition, false, search, {}, true, true, this.screen, true,true,true, (screen)=> {})
        let actions_map = {}
        // let default_save_action = field_screen.default_save_action
        
        actions_map['save'] = (records) => { this.executeAndReload(records[0], field_screen) }
        field_screen.set_actions_map(actions_map)
        return field_screen
        
        
    }


    openFilterScreen(filter_id=null){
        if(!this.screen.editable_view){
            return
        }
        const view_definition = this.config.get_definition('new_field')
        if(!view_definition){
            return
        }
        let search = {}
        if(filter_id){
            search = {'current_search':[['filter_id','=',filter_id]]}
        }

        function _add_filter(new_screen){
            let new_values = {
                'is_filter':true,
                'filter_id': filter_id ? filter_id:-1
                             }
             if(new_screen.active_record){
                new_screen.active_record.set_values(new_values)
             }
             if(new_screen.parent.type != 'board'){
                const template_field = new_screen.get_field_by_name('template')
                template_field.set_visible(false)
             }
             if(new_screen.parent.type == 'board'){
                const filter_field = new_screen.get_field_by_name('filter_field')
                filter_field.set_visible(false)
             }
             const filter_operator = new_screen.get_field_by_name('filter_operator')
             const record = new_screen.active_record
             const filter_field_value = record.get_value('filter_field.')
             if(filter_field_value && filter_field_value.type){
                filter_operator.set_options(filter_operator.get_filter_operators(filter_field_value.type))
             }
        }

        const field_screen = this.screen.modal_childs.addScreen(view_definition,
                                                                false, 
                                                                search, 
                                                                {}, 
                                                                true, 
                                                                true, 
                                                                this.screen, 
                                                                true,
                                                                true,
                                                                true, 
                                                                (screen)=> _add_filter(screen))
        let actions_map = {}
        field_screen.set_title('Filter')
        
        actions_map['save'] = (records) => { this.reloadScreen(records, field_screen, this.screen,{'reload_fields':true}) }
        // actions_map['save'] = (records) => { this.executeAndReload(records[0], field_screen) }
        
        field_screen.set_actions_map(actions_map)
        return field_screen
        
        
    }
   

    async reloadScreen(records, screen, current_screen, args={}){
        await screen.default_save_action.execute(records,false,false,true)
        if(args.reload_fields){
            await current_screen.loadFields({},{},true, {'reset_cache':true})
        }
        await current_screen.reinitialize()
        
    }

    openViewScreen(){
        if(!this.screen.editable_view){
            return
        }
        const view_definition = this.config.get_definition('view_edition')
        let actions_map = {}
        const search = {'current_search':[['id','=',this.screen.id]]}
        const view_screen = this.screen.modal_childs.addScreen(view_definition, false, search, {}, true, true, this.screen, true,true,true, (screen)=> {})
        actions_map['save'] = (records) => { this.reloadScreen(records, view_screen, this.screen) }
        view_screen.set_actions_map(actions_map)
    }
    openBoardWidgetScreen(widget_id=null){
        if(!this.screen.editable_view){
            return
        }
        const view_definition = this.config.get_definition('board_widget_edit_form')
        if(!view_definition){
            return
        }
        let search = {}
        if(widget_id){
            search = {'current_search':[['id','=',widget_id]]}
        }
        const widget_screen = this.screen.modal_childs.addScreen(view_definition, false, search, {}, true, true, this.screen, true,true,true, (screen)=> {})
        let actions_map = {}
        
        actions_map['save'] = (records) => { this.reloadScreen(records, widget_screen, this.screen) }
        widget_screen.set_actions_map(actions_map)
        return widget_screen
        
        
    }

    openActionsScreen(){
        if(!this.screen.editable_view){
            return
        }
        const view_definition = this.config.get_definition('actions_edit_list')
        const search = {'current_search':[['view','=',this.screen.id]]}
        const actions_screen = this.screen.modal_childs.addScreen(view_definition, false, search, {}, true, true, this.screen, true,true,true, (screen)=> {})

    }

    get_draft_operation(action){
        if(this.draft_operations[action]){
            return this.draft_operations[action]
        }
        return false
    }
    add_draft_operation(action, data){
        let draft_operations = {...this.draft_operations}
        draft_operations[action] = data
        console.log("SETTING DRAFT OPERATION")
        console.log(draft_operations)
        this.draft_operations = draft_operations

    }
    remove_draft_operation(name){
        if(this.draft_operations[name]){
            delete this.draft_operations[name]
        }
    }


    



}
   

    