import React, { useState, useContext, useEffect, useReducer } from 'react';
import FormView from './form/FormView.js'
import NewFormView from './form/NewFormView.js'
import BoardView from './board/BoardView.js'
import NewBoardView from './board/NewBoardView.js'
import GraphView from './graph/GraphView.js';
import SpreadSheet from './spreadsheet/Spreadsheet.js'
import HtmlView from './html/HtmlView.js'
import ModalView from './ModalView';
import CardList from './card_list/CardList'
import FlowChartView from './flowchart/FlowChartView.js'
import { AppContext } from 'kalenis-portal-frontend-main'
import { observer } from 'mobx-react-lite'
import '../tailwind.css';
import { extractFilters } from '../common/common.js';
import CalendarView from './calendar/CalendarView.js';
import 'moment/locale/es';



export const View_ = observer(function View_(props) {
    const type = props.screen.type
    const loadingFields = props.screen.loadingFields
    const modal_childs = props.screen.modal_childs.screens || {}
    const { container, app_context } = props;
    
    return (
        <React.Fragment>
            {!loadingFields &&
                <React.Fragment>
                    {type === "list" || type === "spreadsheet" ?
                        <React.Fragment>
                            {props.screen.fields.length > 0 &&
                                <React.Fragment>
                                    {props.screen.list_type == 'card_list' ?
                                        <CardList
                                            screen={props.screen}
                                            height={props.height}
                                            headless={props.headless}
                                            hide_toolbar={props.hide_toolbar}
                                            container={container}
                                            
                                        />
                                        :
                                        <SpreadSheet
                                            screen={props.screen}
                                            height={props.height}
                                            headless={props.headless}
                                            hide_toolbar={props.hide_toolbar}
                                            container={container}
                                            header_props={props.header_props ? props.header_props:{}}
                                            

                                        />
                                    }

                                </React.Fragment>
                            }
                        </React.Fragment>

                        : type === "form" ?
                            <React.Fragment>
                                {props.screen.use_view_layout ?
                                    <NewFormView
                                        screen={props.screen}
                                        containerStyle={props.containerStyle || {}}
                                        container={container}

                                    />
                                    :
                                    <FormView
                                        screen={props.screen}
                                        containerStyle={props.containerStyle || {}}
                                        container={container}

                                    />
                                }
                                
                            </React.Fragment>
                            :
                             type === "calendar" ?

                            <CalendarView
                                screen={props.screen}
                                containerStyle={props.containerStyle || {}}
                                container={container}
                            />
                            :
                            type === "board" ?
                            <React.Fragment>
                                {props.screen.use_view_layout ?
                                    <NewBoardView
                                        screen={props.screen}
                                        container={container}
                                    />
                                    :
                                    <BoardView
                                        screen={props.screen}
                                        container={container}
                                    />
                                }
                            </React.Fragment>
                            : 
                            type === "html" ?
                                <HtmlView
                                    screen={props.screen}
                                    height={props.height}
                                    container={container}
                                // view={props.view}
                                // widgets={widgets}
                                />
                            :
                            type === "graph" ?
                                <GraphView
                                    screen={props.screen}
                                    height={props.height}
                                    hide_toolbar={props.hide_toolbar}
                                    container={container}
                                />

                            :
                            type === "flowchart" ?
                                <FlowChartView
                                    screen={props.screen}
                                    height={props.height}
                                    hide_toolbar={props.hide_toolbar}
                                    container={container}
                                />

                            :
                            <div>Not supported view</div>



                    }
                    <React.Fragment>
                        {Object.keys(modal_childs).map((s, i) => (
                            <ModalView
                                key={i}
                                open={true}
                                screen={modal_childs[s]}
                            />


                        ))}
                    </React.Fragment>




                </React.Fragment>
            }


        </React.Fragment>
    )

}
)
