import React, { useState, Fragment } from 'react';
import { observer } from 'mobx-react-lite'
import FormButton from '../ui/buttons/FormButton';
import Select from 'react-select';
import clsx from 'clsx';
import ViewEditorDropdown from '../editor/ViewEditorDropdown.js';

const base_cls = `field text-primary font-medium bg-transparent focus:placeholder-transparent placeholder-gray-600 placeholder-opacity-60 focus:outline-none rounded-lg w-full appearance-none leading-normal`;
// const base_container_cls = 'bg-white border border-gray-300 flex flex-row flex-wrap text-gray-600 text-xs font-medium scale-left-to-right-one-time lg:justify-end'


const customSelectStyles = {
    menu: (provided, state) => ({
        ...provided,
        borderRadius: '0px',
        marginTop: '0px'
    }),

    control: (provided, state) => ({

        ...provided,
        border: '0px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        // padding: '0px',
        minHeight: '0px',
        width: '100%'


    }),

    indicatorSeparator: (provided, state) => ({

        ...provided,
        display: 'none',




    }),
    dropdownIndicator: (provided, state) => ({

        ...provided,
        // display: 'none',
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',
        // width: '0px',
        color: 'inherit',
        // ':hover': {

        //     display: 'flex',
        // },



    }),
    input: (provided, state) => ({

        ...provided,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',
        width: '100%'



    }),
    valueContainer: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',




    }),
    singleValue: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        padding: 0,
        maxHeight: '1.5rem',
        fontSize: 'inherit',
        fontWeight: 'inherit',

        color: 'inherit',
        fontFamily: 'inherit',




    }),
    menu: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        fontSize: '0.75rem',
        fontWeight: '500',
        color: 'rgba(113, 128, 150,1)',
        fontFamily: 'inherit',

    }),
    option: (provided, state) => ({

        ...provided,
        cursor: 'pointer',


    }),

    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 9999
    }),
    multiValue: (provided, state) => ({
        ...provided,
        border: "1px solid #CBD5E0",
        backgroundColor: "white",
        borderRadius: "5rem",
        paddingLeft: ".25rem",
        paddingRight: ".25rem",
        marginBottom: ".45rem",
    }),

    multiValueLabel: (provided, state) => ({
        ...provided,
        color: "rgba(113, 128, 150,1)",
        fontWeight: "500",
    }),

    multiValueRemove: (provided, state) => ({
        ...provided,
        ":hover": {},
    }),



}


const ViewEditionButton = observer(function ViewEditionToolbar(props) {
    const { name, callback, value } = props



    return (
        // <div className='text-gray-600 border-r border-l px-2 border-gray-300 text-xs font-medium'>
        <div className='px-2 py-2 border-gray-300 flex items-center scale-left-to-right-one-time'>
            <FormButton
                variant="list"
                state_attrs={{}}
                className=" truncate text-xs py-1 px-1"
                color={'transparent'}
                // onDragStart={(a, b, c, d, event) => {
                //     console.log("ON DRAG START of the edition button, ViewEditionToolbar.js")
                //     event.stopPropagation()
                // }}
                // onMouseDown={(e)=>e.stopPropagation()}
                onClick={(e) => {
                    e.stopPropagation()
                    callback(value)
                }}>
                {name}
            </FormButton>
        </div>
        // </div>

    )

});


const ViewEditionToolbar = observer(function ViewEditionToolbar(props) {
    const { screen, container_classname, hide_edition_buttons, hide_extra_options } = props
    const editor = screen.editor
    const locale = screen.locale
    const translate = locale ? (term) => { return locale.translate(term) } : (term) => term
    const draft_operations = editor.draft_operations
    const view_layout = screen.view_layout
    
    const columns_label = translate('Columns')
    const margin_x_label = translate('Horizontal Margin')
    const margin_y_label = translate('Vertical Margin')
    const base_container_cls = 'bg-white border border-gray-300 text-gray-600  text-xs   max-w-full  w-full font-medium scale-left-to-right-one-time grid grid-flow-col items-center justify-end flex-grow grid-rows-2 lg:grid-rows-1'
    // const base_container_cls = 'bg-white border border-gray-300 text-gray-600  text-xs flex-grow  max-w-full auto-cols-auto auto-rows-auto  w-full font-medium scale-left-to-right-one-time grid grid-flow-col items-center  overflow-auto  mb-2 sticky top-0 '
    const className = clsx(base_container_cls, container_classname)
    // const base_colspan = 'col-span-6 md:col-span-1'
    const base_colspan = 'col-span-6 lg:col-span-1'
    const item_classname = 'border-r border-l px-2 border-gray-300 ' + base_colspan
    const edition_buttons = editor.edition_buttons


    return (
        <>
            {editor.design_mode &&
            <div className='flex justify-end'>
                <div className={className} onMouseDown={(e) => e.stopPropagation()}>
                    {Object.keys(draft_operations).map((name, index) => (
                        <div className={base_colspan}>
                            <ViewEditionButton {...draft_operations[name]} />
                        </div>

                    ))}
                    {!hide_edition_buttons &&
                    <>
                        {edition_buttons.map((button, index) => (
                            <div className={base_colspan}>
                                <ViewEditionButton 
                                {...button}
                                    />
                            </div>

                        ))}
                    </>
                    }
                    

                    {/* {!hide_add_fields &&
                    <div className={base_colspan}>
                        <ViewEditionButton name={translate('+ Add Field')} callback={() => { editor.openFieldScreen() }} value={''} />
                    </div>
                    }
                    {!hide_add_actions &&
                    <div className={base_colspan}>
                        <ViewEditionButton name={translate('Actions')} callback={() => { editor.openActionsScreen() }} value={''} />
                    </div>
                    } */}


                    {view_layout &&
                        <>
                            <div className={item_classname}>Breakpoint: {screen.editor.current_breakpoint}</div>
                            <div className={item_classname}>
                                <div>{columns_label}</div>
                                <input
                                    label={columns_label}
                                    value={view_layout.current_cols}
                                    className={base_cls}

                                    onChange={(e) => { view_layout.set_breakpoint_cols(e.target.value) }}
                                    // onBlur={(e) => { props.onBlur(e, e.target.value) }}
                                    //type={field.hide_value ? 'password':''}
                                    type='number'
                                    placeholder={columns_label}
                                    autoComplete="off"

                                />
                            </div>
                            <div className={item_classname}>
                                <div>{margin_x_label}</div>
                                <input
                                    label={margin_x_label}
                                    className={base_cls}
                                    value={view_layout.x_margin}
                                    onChange={(e) => { view_layout.set_x_margin(e.target.value) }}
                                    type='number'
                                    placeholder={margin_x_label}
                                    autoComplete="off"

                                />
                            </div>
                            <div className={item_classname}>
                                <div>{margin_y_label}</div>
                                <input
                                    label={margin_y_label}
                                    className={base_cls}
                                    value={view_layout.y_margin}
                                    onChange={(e) => { view_layout.set_y_margin(e.target.value) }}
                                    type='number'
                                    placeholder={margin_y_label}
                                    autoComplete="off"

                                />
                            </div>
                            <div className={item_classname}>
                                <div className='px-2 w-24'>
                                    <div>{translate('Overlap')}</div>

                                    <Select
                                        placeholder={''}
                                        value={view_layout.current_allow_overlap}
                                        options={[
                                            { 'label': translate('Yes'), 'value': true },
                                            { 'label': translate('No'), 'value': false }
                                        ]}
                                        onChange={(value) => view_layout.set_allow_overlap(value.value)}
                                        getOptionLabel={option => option.label}
                                        getOptionValue={option => option.value}
                                        styles={customSelectStyles}
                                    />



                                </div>
                            </div>


                        </>
                    }
                    {!hide_extra_options &&
                        <div className='col-span-2 md:col-span-1'>
                            <ViewEditorDropdown screen={screen} />
                        </div>
                    }
                </div>
                 </div>
            }
        </>







    )

});
export default ViewEditionToolbar;