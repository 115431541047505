import { makeObservable, action, observable } from 'mobx'
import { Screen } from '../screen/Screen'
import { ScreenGroup } from '../screen/ScreenGroup'
import {evalExpr} from '../common'
import { environment } from '../common'
import moment from 'moment';

const server_formats = {
    'date': 'YYYY-MM-DD',
    'datetime': 'YYYY-MM-DD HH:mm:ss',
    'time': 'hh:mm:ss'
}

const defaults = {
    'lang':'en',
    'date_format':'%m/%d/%Y',
    'time_format':'%H:%M:%S',
    'decimal_point':'.',
    'thousands_sep':','
}
class Locale {
    lang=""
    date_format=""
    decimal_point=""
    thousands_sep=""
    time_format=""
    local_translations = {}

    get datetime_format(){
        return this.date_format + ' ' + this.time_format
    }

    get formats(){
        return {
            'date':this.date_format,
            'datetime':this.datetime_format
        }
    }

    constructor(connection, values){
        this.values = values 
        this.connection = connection
        this.set_values(values)
        this.loadLocalTranslations()
        // this.local_translations = {
        //     '+ Add Field':'+ Añadir Campo',
        //     'Actions': 'Acciones',
        //     'Columns':'Columnas',
        //     'Horizontal Margin':'Margen Horizontal',
        //     'Vertical Margin':'Margen Vertical',
        //     'Overlap':'Superposición',
        //     'Yes':'Si',
        //     'No':'No',
        //     'New':'Nuevo',
        //     'Delete':'Eliminar',
        //     'Go to:':'Ir a:',
        //     'Reports':'Reportes',
        //     'More':'Mas',
        //     'Page':'Página',
        //     'of':'de',
        //     'Results':'Resultados',
        //     'Previous Page':'Pág. Anterior',
        //     'Next Page':'Pág. Siguiente',
        //     'selected record':'registro seleccionado',
        //     'selected records':'registros seleccionados',
        //     'Sort':'Ordenar',
        //     'Group By':'Agrupar por',
        //     '+ Add Filter':'+ Añadir Filtro',
        //     'Search':'Buscar',
        //     'Filters':'Filtros',
        //     'Field':'Campo',
        //     'Operator':'Operador'

        // }
       

    }

    async loadLocalTranslations(){
        const abortController = new AbortController();
        let args = {
            'environment':environment()
        }
        this.local_translations = await this.connection.dispatch('GET', '/user/local_translations', args, false, true, false, abortController)

    }

    moment_format(format){
        if(!format){
            format = this.date_format
        }
        return format
        .replace('%a', 'ddd')
        .replace('%A', 'dddd')
        .replace('%w', 'd')
        .replace('%d', 'DD')
        .replace('%b', 'MMM')
        .replace('%B', 'MMMM')
        .replace('%m', 'MM')
        .replace('%y', 'YY')
        .replace('%Y', 'YYYY')
        .replace('%H', 'HH')
        .replace('%I', 'hh')
        .replace('%p', 'A')
        .replace('%M', 'mm')
        .replace('%S', 'ss')
        .replace('%f', 'SSS')
        .replace('%z', 'ZZ')
        .replace('%Z', 'zz')
        .replace('%j', 'DDDD')
        .replace('%U', 'ww')
        .replace('%W', 'WW')
        .replace('%c', 'llll')
        .replace('%x', 'L')
        .replace('%X', 'LTS')
        .replace('%', '%%')
        ;
    }

    

    format_date(date, type='date', format=false){
        if(!format){
            format = this.formats[type]
        }
        date = type=='date' ? moment.utc(date):moment.utc(date).local()
        
        return date.format(this.moment_format(format))
    }

    format_date_value(date, type='date'){
        if(typeof(date) == 'string'){
            date = moment(date, this.moment_format(this.formats[type]))
        }
        date = moment.utc(date)
        if(!date._isValid){
            return null
        }
        return date.format(server_formats[type])
    }

    set_values(values){
        if(!values || !values['lang']){
            values = defaults
        }
        this.lang = values.lang
        this.date_format = values.date_format
        this.time_format = values.time_format
        // TODO: decimal point and thousands sep formatting on numbers
        this.decimal_point = values.decimal_point
        this.thousands_sep = values.thousands_sep
    }

    translate(term){
        if(this.lang == 'en'){
            return term
        }
        return this.local_translations[term] || term
    }


}
export class UserContext {
    screen=""
    connection=""
    base_values = {}
    initialized = false
    appbar_expression = ""
    view_editor = false
    role = null
    lang = null
    locale = null

    get appbar_data(){
        if(this.screen && this.screen.active_record){
            return evalExpr(this.screen.active_record.get_all_values(), this.appbar_expression).str
        }
        return ""
    }
    constructor(connection, values){
        makeObservable(this,{
            setScreen:action,
            screen:observable,
            initialized:observable,
            role:observable,
            lang:observable,
            locale:observable,
            setRole:action,
            setLang:action,
            setLocale:action

        })
        console.log("-------------------Constructing USER CONTEXT---------------")
        this.connection = connection
        this.base_values = values
        this.setRole(values.role)
        this.setLang(values.lang)
        this.getUserContextView()
        this.appbar_expression = ""
        this.view_editor = values.view_editor
        this.locale = new Locale(this.connection, values.locale)

    }
    
    setValues(values){
        console.log("NOT SETTING THE VALUES YET...")
        console.log(values)
        // const record = this.screen.active_record
        // record.set_values(values)
        this.setLang(values.lang)
        this.setRole(values.role)
        this.setLocale(values.locale)

    }
    get_all_values(){
        const record = this.screen.active_record
        let values = {}
        this.screen.fields.forEach(function(field){
            if(field.has_value(record)){
                values[field.name] = record.get_value(field.name)
            }
        })
        // return this.screen.active_record.get_all_values()
        return values
    }
    get_field(fname){
        return this.screen.get_field_by_name(fname)
    }
    get_value(fname){
        const field = this.get_field(fname)
        return field.get_value(this.screen.active_record)
    }
    get_string_value(fname){
        const field = this.get_field(fname)
        if(!field){
            return ""
        }
        return field.get_string_value(this.screen.active_record)
    }

    setLocale(values){
        this.locale.set_values(values)
    }
    reloadRole(new_role){
        this.setRole(new_role)
        this.connection.loadRoutes(this.role)
    }

    async updateUserContext(values){
        const abortController = new AbortController();
        let args = {
            'environment':environment(),
            'values':values
        }
        let new_context = await this.connection.dispatch('POST', '/user/context/update', args, false, true, false, abortController)
        await this.connection.loadRoutes()
        this.setValues(new_context)
        this.locale.loadLocalTranslations()
        this.connection.toogleLoading()
        

    }

    onRecordUpdate(record){
        let new_record_role = record.get_value('role')
        let new_lang = record.get_value('lang')
        const role_changed = (new_record_role && new_record_role != this.role)
        const lang_changed = (new_lang && new_lang != this.lang)
        if(lang_changed || role_changed){
            this.connection.toogleLoading()
            if(lang_changed){
                if(this.connection.main_screen_group && this.connection.main_screen_group.active_screen){
                    this.connection.main_screen_group.clear()
                }
                this.connection.cache.clear()
                
            }
            this.updateUserContext(record.get_all_values())
        }
        else{
            if(this.connection.main_screen_group && this.connection.main_screen_group.active_screen){
                this.connection.main_screen_group.active_screen.do_search()
            }
        }

        
        
        
    }
    createScreen(definition){
        let group = new ScreenGroup(this.connection, true)
        
        // let screen = new Screen({}, definition.view, this.connection,false, [], {},false, false,false,false)
        // let screen = group.addScreen({}, definition.view, this.connection,false, [], {},false, false,false,false)
        let screen = group.addScreen(definition.view,false,[],{},true,false,false,false,false, false, false, false, (record) => this.onRecordUpdate(record) )
        screen.createFields(definition.fields)
        screen.createActions(definition.actions)
        screen.loadingFields= false
        
        return screen
    }

    setScreen(definition){
        let screen = this.createScreen(definition)
        // let stored_values = JSON.parse(this.connection.storage.getItem('kalenis_login_info'))
        // let values = login_info ? login_info:{'id':-1}

        let record = screen.data.addRecord({values:this.base_values})
        screen.set_active_record(record)

        screen.data.loadingInitialData = false
        screen.data.loading = false

        //Map 'login' action to getToken method
        screen.set_actions_map({
            'logout': ()=>{this.connection.logout()}
        })
        this.screen = screen
        this.initialized = true

        

    }
    setRole(role_id){
        if(!role_id && this.screen && this.screen.active_record){
            role_id = this.screen.active_record._values['role']
        }
        this.role = role_id
    }
    setLang(lang_id){
        if(!lang_id && this.screen && this.screen.active_record){
            lang_id = this.screen.active_record._values['lang']
        }
        console.log("SETTING LANG")
        console.log(lang_id)
        this.lang = lang_id
    }

    async getUserContextView(){
        const abortController = new AbortController();
        let args = {
            'environment':environment()
        }
        let view_info = await this.connection.dispatch('GET', '/user/context_view', args, false, true, false, abortController)
        if(!view_info){
            return
        }
        // let stored_data = await this.connection.storage.getItem('kalenis_login_info');
        
        // if(stored_data){
        //     login_info = JSON.parse(stored_data)
        // }
        
        if(view_info){
            this.setScreen(view_info.view)    
            this.appbar_expression = view_info.appbar_info
        }
        
    }

    

}