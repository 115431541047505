import React, { useState, useEffect, useContext, Fragment, forwardRef, createRef } from 'react';
import { observer } from 'mobx-react-lite'
import clsx from 'clsx';
import { Responsive, WidthProvider } from "react-grid-layout";

const ResponsiveGridLayout = WidthProvider(Responsive);


const GridHoc = observer(function GridHoc(props) {
    
    const { screen, children, rowHeight, isDroppable, layoutChangeCallback } = props;
    // const {layouts, cols, breakpoints, margin} = props.layout ? props.layout:screen.layout
    const view_layout = screen.view_layout
    const editor = screen.editor
    const edition_mode = editor ? editor.design_mode:false
    const {cols, breakpoints, margin} = view_layout
    const {layouts} = view_layout.current_layout ? view_layout.current_layout:[]
    const cls = edition_mode ? "layout grid_in_edition":"layout"

    console.log("Layouts on HOC")
    console.log(layouts)
    function onLayoutChange(layout, layouts){
        if(edition_mode){
            console.log("SETTING NEW LAYOUT")
            view_layout.set_layout_change(layouts, editor.current_breakpoint)   
        }
        if(layoutChangeCallback){
            layoutChangeCallback()
        }
    }

    function onBreakpointChange(newBreakpoint, newCols){
        editor.set_current_breakpoint(newBreakpoint)

        
    }
    function onDropTest(layout, layoutItem, _event){
        _event.preventDefault()
        _event.stopPropagation()

        alert(`Dropped element props:\n${JSON.stringify(layoutItem, ['x', 'y', 'w', 'h'], 2)}`);
    }
    
    return (
                <>
                    <ResponsiveGridLayout
                        className={cls}
                        layouts={layouts}
                        breakpoints={breakpoints}
                        rowHeight={rowHeight}
                        // compactType='vertical'
                        compactType={view_layout.compact_type}
                        // margin={[10,10]}
                        margin={margin || [10,10]}
                        allowOverlap={view_layout.allow_overlap}
                        preventCollision={false}
                        
                        isDraggable= {edition_mode}
                        isResizable = {edition_mode}
                        onLayoutChange={(layout, layouts) =>
                            onLayoutChange(layout, layouts)
                          }
                        onBreakpointChange={onBreakpointChange}
                        cols={cols}
                        onDragStart={props.onDragStart}
                        isDroppable={isDroppable}
                        onDrop={onDropTest}
                        
                        
                    >

                        {children}


                    </ResponsiveGridLayout>

                
            
        </>


    )

});

export default GridHoc;


