import React, {Fragment, createRef, forwardRef} from 'react';
import { observer } from 'mobx-react-lite'
import QuickFilter from '../fields/QuickFilter'
import Paper from '../surfaces/Paper';
import GridHoc from '../GridHoc';

const AreaFilter = observer(forwardRef(function AreaFilter(props, ref) {
    const { filter, screen, edition_mode } = props;
    // const edition_mode = field.screen.editor ? field.screen.editor.design_mode:false

    return (
        <div 
            className="flex w-full" 
            ref={ref}
            key={filter.original_name}
            {...props}
            >
            <QuickFilter
                filter={filter}
                screen={screen}
                hideLabel={false}
                
                />
            {props.children}
        </div>
    )
}));

export const QuickFiltersArea = observer(function QuickFiltersArea(props) {
    const { screen } = props
    const quick_filters = screen.filters ? screen.filters.quick_filters : []
    const sub_screen = screen.filters.sub_screen
    const editor = sub_screen.editor
    const edition_mode = editor ? (editor.design_mode || sub_screen.main_screen_design_mode):false

    const children = React.useMemo(() => {
        return quick_filters.map((filter, index) => {
            return <AreaFilter 
                        ref={createRef()}
                        key={filter.original_name}
                        filter={filter}
                        edition_mode={edition_mode}
                        screen={screen}
                    />
                    
                    


        });
    }, [quick_filters, edition_mode]);

    return (
        <>
        {quick_filters.length ?
            <div className='w-full bg-white border border-gray-300 p-2'>

                <GridHoc
                    screen={sub_screen}
                    rowHeight={42}

                >
                    {children}
                </GridHoc>
                
            </div>
        :
            <></>
        }
        </>
        


    )

}
)
export default QuickFiltersArea

// import React, {Fragment} from 'react';
// import { observer } from 'mobx-react-lite'
// import QuickFilter from '../fields/QuickFilter'
// import Paper from '../surfaces/Paper';

// export const QuickFiltersArea = observer(function QuickFiltersArea(props) {
//     const { screen } = props
//     const quick_filters = screen.filters ? screen.filters.quick_filters : []

//     const children = React.useMemo(() => {
//         return quick_filters.map((filter, index) => {
//             return <div 
//                     className="flex w-full" key={filter.id}
//                     >
//                         <QuickFilter
//                             filter={filter}
//                             screen={screen}
//                             hideLabel={false}
//                             />
//                     </div>


//         });
//     }, [quick_filters]);

//     return (
//         // <Paper className='px-2 py-2 grid grid-cols-1 gap-y-3.5 md:grid-cols-4 lg:grid-cols-6 grid-auto-cols'>

//         //         {children}
//         //     </Paper>
//         <>
//         {quick_filters.length ?
//             <Paper className='px-2 py-2 grid grid-cols-1 gap-y-3.5 md:grid-cols-4 lg:grid-cols-6 grid-auto-cols'>

//                 {children}
//             </Paper>
//         :
//             <></>
//         }
//         </>
        


//     )

// }
// )
// export default QuickFiltersArea