import React, { useState, useEffect, useContext, Fragment } from 'react';
import Paper from '../../ui/surfaces/Paper.js'
import Field from '../../ui/fields/Field.js'
import FormDivider from '../../ui/fields/FormDivider.js'
import FormButton from '../../ui/buttons/FormButton.js'
import LoadingIndicator from '../../common/LoadingIndicator'
import { observer } from 'mobx-react-lite'
import ViewHeader from '../ViewHeader'
import clsx from 'clsx';
import ViewEditionToolbar from '../ViewEditionToolbar.js';
const baseFieldCont = "w-full"


const FormField = observer(function FormField(props) {
    const { field, record, default_columns } = props;
    const state_attrs = field.get_state_attrs(record)
    let cls = field.grid_cls

    return (
        <div 
            style={{ display: state_attrs.invisible ? 'none' : '' }} 
            key={field.name} 
            className={cls}
            onContextMenu={function(e){
                e.preventDefault()
                field.open_context_menu(e.currentTarget, record)
                
            }}
            >
                <Field
                    field={field}
                    record={record}
                    variant="outlined"
                    show_tooltip
                    show_resources
                />
               
        </div>
    )
});
// function FormView(props) {
const FormView = observer(function FormView(props) {


    const container_ref = React.createRef()
    const { screen } = props;
    const fields = screen.visible_fields;
    const record = screen.active_record
    const loading = screen.processing
    const default_columns = screen.default_columns
    const columns_classname = "grid-cols-"+default_columns
    const grid = clsx("flex-grow grid  w-full gap-x-5 gap-y-4 py-4 px-4", columns_classname)



    return (
        <div ref={container_ref}>
        <ViewEditionToolbar
            screen={screen}
            container_classname='mb-2 sticky top-0'
            />
        <Paper className="py-2 px-2 rounded" style={props.containerStyle}>

            {/* <div className="text-xl px-6 py-1 text-gray-800">{screen.title}</div> */}
            <ViewHeader
                 screen={screen}
                 title_classname="text-xl px-6 py-1 text-gray-800"
                 hide_toolbar={props.hide_toolbar}
                 isSpreadsheet={false}
            />
            <div  className={grid}>
                {fields && record ?
                    <>
                        {fields.map((field, index) => (

                            <FormField
                                key={field.id}
                                field={field}
                                record={record}
                                default_columns={default_columns} />

                        ))}
                    </>
                    :
                    ""
                }



            </div>
            <LoadingIndicator
                ref={container_ref}
                show={loading}
                fullscreen
                
            />
        </Paper>
        </div>


    )

});

export default FormView;