import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from '../Screen'
import { ScreenLayout } from "../ScreenLayout"
import { BoardWidgets } from './BoardWidget'
import {grid_layout_defaults} from "../../ui/ui_defaults"
export class BoardScreen extends Screen {
    widgets = []
    child_screens = []
    default_columns = 2
    widgets_loaded = false

    get view_widgets() {
        return this.widgets.filter(function (widget) { return widget.type === 'view' })
    }
    get action_widgets() {
        return this.widgets.filter(function (widget) { return widget.type === 'action' })
    }
    get search_connected_widgets() {
        return this.widgets.filter(function (widget) { return widget.search_connected })
    }
    get not_search_connected_widgets() {
        return this.widgets.filter(function (widget) { return !widget.search_connected })
    }
    constructor(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false) {
        super(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false)
        this.default_columns = view.default_columns
        this.child_screens = group.createGroup(this.connection)
        this.widgets_loaded = false
        makeObservable(this, {
            widgets: observable,
            widgets_loaded:observable,
            createWidgets:action
        })

    }

    async initialize(search) {
        await this.loadFields()
        await this.loadWidgets()
        if(this.use_view_layout){
            this.view_layout = new ScreenLayout(this, this.view.view_layout)
        }
        
        await this.do_search(search)


    }
 
    createWidgets(data) {
        this.widgets = data.map(function (w) {
            return new BoardWidgets[w.type](w, this)
        }.bind(this))
        this.widgets_loaded = true
    }

    async loadWidgets() {
        let args = { view_id: this.view.id }
        const abortController = new AbortController();
        let widgets = await this.connection.dispatch('GET', '/view/widgets', args, false, false, false, abortController)

        runInAction(() => {
            this.createWidgets(widgets)

        })

    }

  

    async _delete_widget(widget_ids){
        let args = { ids: widget_ids }
        const abortController = new AbortController();
        let res = await this.connection.dispatch('POST', '/view_handler/board/widget/delete', args, false, false, false, abortController)

        if(res){
            await this.reinitialize()
            this.notifications.addSnack(
                { message: "Widget Deleted", level: 'error',timeout:2000 }
            )
            
        }
    }
    async deleteWidget(widget_ids){
        this.notifications.addDialog({
            'message':'Are you sure you want to delete this widget ?', 
            'html':true,
            'actions':[
                {'name':'OK', 'color':'primary', 'callback':()=>{
                    this._delete_widget(widget_ids)
                    return true
                }}
            ]}, 
            true)  

    }

    async do_search(search, force) {
        // let count = false
        // let reset_page = false
        
        if (search) {
            // this.search = search
            this.current_search = search.current_search || this.current_search
            this.action_params = search.action_params || this.action_params
            this.action_id = search.action_id || false
            this.order = search.order || this.order
            
        }
        if (this.filterable) {
            this.filters.setInitialFilters()
        }

        this.search_connected_widgets.forEach(function(connected_widget){
            connected_widget.screen.do_search(connected_widget.current_search)
        })
        
        this.not_search_connected_widgets.forEach(function(child_view){
            child_view.screen.do_search()
        })
        
        
        

    }


}