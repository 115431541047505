import React from 'react';



const base_cls = " transition duration-300 font-medium text-sm outline-none "



const colors = {
  	'primary':' bg-primary text-white hover:bg-primary-lighten',
  	'secondary':' bg-secondary text-white',
  	'transparent':'bg-transparent text-primary hover:bg-light-selected',
  	'disabled':' bg-disabled text-gray-500 pointer-events-none',
  	'warning':' bg-transparent text-red-600 hover:bg-red-600 hover:bg-opacity-15',
    'off': ' text-gray-500 hover:bg-light-selected hover:text-primary',
}
const shapes = {
  default: " flex items-center rounded",
  square: " flex items-center rounded-md",
  circle: " flex items-center rounded-full",
};

const sizes = {
  sm: " p-0",
  default: " p-1.5",
  lg: " p-4",
  md: " p-1",
};

export function IconButton(props) {

    const cls = () => {
    let base = props.className ? base_cls.concat(props.className) : base_cls
    return base.concat(
      colors[`${props.disabled === true ? "disabled" : props.color}`],
      props.shape ? shapes[props.shape] : shapes["default"],
      props.size ? sizes[props.size] : sizes["default"]
    )
    }
    
    return (
    <button
      type={props.type || 'button'}
      className={cls()}
      style={props.style}
      onClick={props.onClick}
      title={props.title}
      disabled={props.disabled}
    >
      <div className={`${props.size === "sm" ? "w-3.5 h-3.5" : "w-4 h-4"}`}>
        {props.children}
      </div>
    </button>

    )

}

export default IconButton;