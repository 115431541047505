
import moment from 'moment';

export const environment = () => {
    if (typeof document != 'undefined') {
        return 'web'
    }
    else if (typeof navigator != 'undefined' && navigator.product == 'ReactNative') {
        return 'native'
    }
    else {
        return 'node'
    }
}

//TODO: Localization
// Will be removed , use user context locale instead
export const formats = {
    'date': 'DD-MM-YYYY',
    'datetime': 'DD-MM-YYYY HH:mm:ss',
    'time': 'h:mm:ss'
}
// Will be removed , use user context locale instead
const server_formats = {
    'date': 'YYYY-MM-DD',
    'datetime': 'YYYY-MM-DD HH:mm:ss',
    'time': 'hh:mm:ss'
}

export const sheet_navigation_signals = [
    'ArrowRight',
    'ArrowLeft',
    'ArrowUp',
    'ArrowDown',
    'Tab',
    'Enter'
]
//Format Methods: Show data on client
// Will be removed , use user context locale instead
export const formatDate = (value, type) => {

    if (value) {
        // 
        let format = formats[type]
        if (type === 'date') {
            value = moment.utc(value).format(format)
        }
        else {
            value = moment.utc(value).local().format(format)
        }


    }
    else {
        value = ""
    }

    return value
}


//TODO  : Review

export const getSelectionOption = (value, field) => {
    if (!field.selection_options) {
        return value
    }
    const option = field.selection_options.filter(function (option) { return option.value === value })

    return option.length ? option[0].label : value
}



//Write Methods: Set data to backend

export const validateNumber = (value) => {

    value = value.replace(',', '.')
    return parseFloat(value);
}

export const validateDate = (value, type) => {
    if (value) {
        // 
        let format = server_formats[type]
        value = moment.utc(value).format(format)
        // if(type === 'date'){
        //     value = moment.utc(value).format(format)
        // }
        // else{
        //     value = moment.utc(value).local().format(format)
        // }


    }
    else {
        value = null
    }

    return value
}

export const validateInput = {
    'integer': validateNumber,
    'float': validateNumber,
    'numeric': validateNumber,
    'char': (value) => { return value }
}

//extract filters from url
//params:UrlSearchParams
export const extractFilters = (params) => {

    let extracted = {
        'current_search': [],
        'order': [],
        'group_by':[],
        'action_params': [],
        'action_id': undefined,
    }

    const extract = (p) => {
        let filter_value = p[1].split(';')
        let val = filter_value[1]

        if (val.startsWith('[') && val.endsWith(']')) {
            val = val.replace('[', "").replace(']', "")
            val = val.split(',')

        }
        // extracted['search'][p[0]] = {operator:filter_value[0],value:val}

        let f = [p[0], filter_value[0], val]
        return f

    }


    for (let p of params) {
        if (p[0] === "_order") {
            extracted['order'] = [p[1].split(',')]
        }
        else if (p[0] === "_group_by") {
            extracted['group_by'] = p[1].split(',')
        }
        else if (p[0] === "_action_id") {
            extracted['action_id'] = parseInt(p[1])
        }
        else if (p[0] === "_ap") {
            extracted['action_params'].push(p[1].split(';'))

        }
        else {

            extracted['current_search'].push(extract(p))

        }


    }


    return extracted
}

export const createUrl = (params) => {
    var query = "?"

    Object.keys(params).map(function (field) {// eslint-disable-line

        if (field === "current_search") {

            let current_search = params[field]

            current_search.forEach(function (domain) {

                const field = domain[0]
                const operator = domain[1]
                let value = domain[2]
                if (value !== "" && value !== undefined) {
                    if (operator) {

                        if (Array.isArray(value)) {
                            value = value.toString()
                            value = '['.concat(value).concat(']')
                        }

                    }
                    value = operator.concat(";").concat(value)
                }
                query = query.concat(field).concat('=').concat(value).concat('&')

            })


        }
        else if (field === 'action_params') {
            let action_params = params[field]

            if (action_params.length) {
                query = query.concat('_ap=')
                action_params.forEach(function (domain) {

                    const field = domain[0]
                    const operator = domain[1]
                    let value = domain[2]
                    if (value !== "" && value !== undefined) {
                        if (operator) {

                            if (Array.isArray(value)) {
                                value = value.toString()
                                value = '['.concat(value).concat(']')
                            }

                        }
                        value = operator.concat(";").concat(value)
                    }
                    query = query.concat(field).concat(';').concat(value).concat('&')

                })
            }


        }

        else if (field === "order") {

            if (params[field].length) {
                query = query.concat('_order').concat("=").concat(params[field].toString())
            }

        }
        else if (field === "action_id") {
            if (params[field]) {
                query = query.concat('_action_id').concat("=").concat(params[field])
            }
        }


    })



    return query
}

// Method used to pass from domain array to values on object (feeds parse_domain)
// domain: array domain ex: [['fname','=',1]]
// Returns: Object with fname:value (operators are dismissed)
export const domain_to_object = (domain) => {
    let res = {}
    domain.forEach(function(pair){
        res[pair[0]] = pair[2]
    })
    return res
}
//args: 
//data:object to extract data from
//expr: string domain ex: [['fname','=',{value}]]
//returns: array domain

export const parse_domain = (data,expr,_parent_record,_parent_selected_records=[])=>{
    let domain = []
    if (!expr) {
        return domain
    }
    //Remove leading  [] and return array with terms as strings
    // let str_terms = expr.substring(1,expr.length).match(/\[.*?\]/g)
    let str_terms = expr.substring(1,expr.length-1).split(',')
    let terms_array = []
    
    let size = 3; 
    for (var i=0; i<str_terms.length; i+=size) {
        terms_array.push(str_terms.slice(i,i+size));
        }
   
    
    terms_array.forEach(function(term){
        //remove leading and trailing [] from the term
        if([term[0], term[1], term[2]].includes(undefined)){
            return
        }
        let fname = term[0].replaceAll("'","").replaceAll(" ","").replaceAll('[',"")
        let operator = term[1].replaceAll("'","").replaceAll(" ","")
        let value = term[2].substring(0,term[2].length-1)
        if (value.includes('{')){
            let expr = value.match(/{([^}]+)}/g)
            if(expr && expr.length){
                expr = expr[0]
                if(expr != value){
                    value = evalExpr(data, value, _parent_record, _parent_selected_records).str
                }
                else{
                    value = browseObject(data, expr, false,{},_parent_record,_parent_selected_records)
                }
                
            }
            
            
            
        }


        domain.push([fname,operator,value])

    })
    

    return domain


}
//args: 
//data:object to extract data from
//expr: string including variables between {}
//returns: {str:string with solved variables, variables:object with solved variables}
export const evalExpr = (data, expr, _parent_record, _parent_selected_records=[]) => {

    if (!expr) {
        return ""
    }

    let variables_obj = {}
    let variables = expr.match(/{([^}]+)}/g)

    let str = expr

    if (!data) {
        if (variables) {
            variables.forEach(function (v) {
                str = str.replace(v, "")
            })
        }

        return str
    }

    if (variables) {
        variables.forEach(function (v) {

            let value = browseObject(data, v, {}, [], _parent_record, _parent_selected_records) || ""


            str = str.replace(v, value)
            variables_obj[v.substring(1, v.length - 1)] = value


        })

    }



    return { str: str, variables: variables_obj }
}

//args: 
//record:record to extract data from
//expr: string including variables between {}
//returns: {str:string with solved variables, variables:object with solved variables}
export const evalRecordExpr = (record, expr, _parent_record, _parent_selected_records=[]) => {

    if (!expr) {
        return ""
    }

    let variables_obj = {}
    let variables = expr.match(/{([^}]+)}/g)

    let str = expr
    const data = record._values

    if (!data) {
        if (variables) {
            variables.forEach(function (v) {
                str = str.replace(v, "")
            })
        }

        return str
    }
    else if(variables){
        variables = variables.map(function(v){
            if (expr.includes('{')) {
                v = v.replace('{', "").replace('}', "")
            }
            const spl = v.split('.')
            const field = record.screen.get_field_by_any_name(spl[0])
            if(field){
                spl[0] = field.name
                const new_var = spl.join('.')

                str = str.replace(v, new_var)
                return '{'+new_var+'}'
            }
            else{
                return v
            }
            
        })
    }
    
    if (variables) {
        variables.forEach(function (v) {

            let value = browseObject(data, v, {}, [], _parent_record, _parent_selected_records) || ""


            str = str.replace(v, value)
            variables_obj[v.substring(1, v.length - 1)] = value


        })

    }



    return { str: str, variables: variables_obj }
}

//args: 
//data:object to extract data from
//expr: string of type party.id
//returns: value of the last part (ex: id) or Array of values
//TODO:OPTIMIZE and check nested arrays (actually only considers arrays if the initial value is array)
export const browseObject = (data, expr, user_context, fields, _parent_record,_parent_selected_records=[]) => {
    let value;

    if (expr.includes('{')) {
        expr = expr.replace('{', "").replace('}', "")
    }
    if (expr.startsWith('_parent_record')) {
        if (!_parent_record) {
            return ""
        }
        data = _parent_record
        expr = expr.replace('_parent_record.', '')

    }
    else if (expr.startsWith('_parent_selected_records')) {
        
        data = {'_parent_selected_records':_parent_selected_records}
        

    }



    if (user_context) {
        data['user_context'] = user_context
    }
    if (!data || !expr) {
        return ""
    }

    
    let res = []
    let spl = expr.split('.')
    if (spl.length > 1) {

        // If the splitted expression is already solved as part of the data, just return
        if(data.hasOwnProperty(expr)){
            return data[expr]
        }
        value = data[spl[0]]

        if (data[spl[0].concat('.')]) {
            value = data[spl[0].concat('.')]
        }

        let expr_array = spl.slice(1, spl.length)

        //initial value is array
        if (Array.isArray(value)) {

            value.forEach(function (val, index) {


                // const expression = expr_array.slice(index, spl.length).join('.')
                const expression = expr_array.join('.')
                const new_val = browseObject(val, expression, false, fields)
                if (new_val) {
                    if (!res.includes(new_val)) {
                        res.push(new_val)
                    }

                }




            })


            return res

        }

        expr_array.forEach(function (dot, index) {

            if (!value && value !=0) {
                return false
            }


            if (value[dot.concat('.')]) {
                dot = dot.concat('.')
            }


            if (!value[dot] && value[dot] !=0) {
                value = ""
                return ""
            }

            value = value[dot]



        })

    }

    else if (data.hasOwnProperty(expr)) {
        
        value = data[expr]
        if(value && typeof value.get_value === 'function'){
            value = value.get_value()

        }

    }
    else {

        value = expr

    }




    return value;

}

export const flatten = (arr) => {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}
export const evalCondition = (p1, operator, p2) => {
    const math_ops = ['<', '>', '>=', '<=']

    if (math_ops.includes(operator)) {
        p1 = parseFloat(p1)
        p2 = parseFloat(p2)

        if (typeof p1 !== 'number' || isNaN(p1)) {
            p1 = 0.00
        }
        if (typeof p2 !== 'number' || isNaN(p2)) {
            p2 = 0.00
            // TODO: NEEDS REVIEW
            // alert("The value " + p2 + "is not valid for the use with the operator " + operator)
            // throw new Error("The value " + p2 + "is not valid for the use with the operator " + operator)
        }
    }

    switch (operator) {
        case '=': return p1 == p2
        case '!=': return p1 != p2
        case 'in': return p2.includes(p1)
        case 'not in': return !p2.includes(p1)
        case '>': return p1 > p2
        case '>=': return p1 >= p2
        case '<': return p1 < p2
        case '<=': return p1 <= p2
        case 'is_empty': return (p1===undefined || p1=="" || p1==null)
        case 'is_not_empty': return (p1!==undefined && p1!="" && p1!=null)
        default:
            alert("El operador " + operator + " no se encuentra soportado")
            throw new Error("Operator " + operator + " not Supported")

    }

}

/**
* Download File
* @param {Url}  File URL
* @param {name} name of the file
 
* @return {void} ""
*/
export const downloadFile = (url, name, screen=false) => {
    name = name ? name:'Download'
    if(name.length >= 50){
        name = name.substring(0, 50).concat('-');
    }
    if(screen && screen.fileHandler){
        return screen.fileHandler(false, url, name)
    }
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

}

/**
 * Read File
 * @param {blob}  File blob
 
 * @return {void} ""
 */
export const readFile = async (value, format='url') => {
    let res = ""
    if (environment() != 'web'){
        if(value.data.readed){
            return value.data.data
        }
        
    }
    if (environment() === 'web' && format==='url') {
        res = URL.createObjectURL(value)
        return res;
    }
    else {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onerror = reject;
            if(environment() === 'web' && value.data){
                value = value.data
            }
            reader.readAsDataURL(value);
            reader.onload = () => {
              resolve(reader.result )
            };
            
          });
        
        }

    }


export const castValue = (ftype, value, operator) => {

    const castInteger = (val)=> {return parseInt(val)}
    const types = {
        'integer': (value) => Array.isArray(value) ? value:castInteger(value),
        'many2one': (value) => castInteger(value)
    }    
    const operators = {
        'in': (value) => Array.isArray(value) ? value:[value],
        '=': (value) => value
    }

    if(!types[ftype]){
        return value
    }

    return operators[operator](types[ftype](value))
}

export const isNumber = (value) => {

    return /^-?[\d.]+(?:e-?\d+)?$/.test(value);
}

// Merge domain A and b, deduplicate, keeps B value.
export const mergeDomain = (current_domain, new_domain) => {
    let domain = [...new_domain]
    const seen_fields = domain.map(function(pair){return pair[0]})

    current_domain.forEach(function(pair){
        if(!seen_fields.includes(pair[0])){
            domain.push(pair)
        }
    })
    
   return domain
}






const common = {
    validateNumber,
    validateInput,
    validateDate,
    createUrl,
    evalExpr,
    evalRecordExpr,
    browseObject,
    formatDate,
    flatten,
    environment,
    readFile,
    formats,
    parse_domain,
    domain_to_object,
    castValue,
    mergeDomain,
    extractFilters
}


export default common;

