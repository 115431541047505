import { action, makeObservable, observable } from "mobx"

export class Cache {
    
    
    constructor() {
        
        this.store = {}   
        makeObservable(this,{
            store:observable,
            get:action,
            set:action
        })     

    }

    get(key){
        return this.store[key]
    }
    set(key, value){
        this.store[key] = value
    }
    clear(){
        this.store = {}
    }


    



}