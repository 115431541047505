import React, { useState, useEffect, useRef } from 'react';
import './VListStyles.css';
import 'react-virtualized/styles.css';

import Container from '../../ui/surfaces/Container.js'
import { ScrollSync, AutoSizer, Grid } from 'react-virtualized';
import clsx from 'clsx';
import scrollbarSize from 'dom-helpers/scrollbarSize';

import ViewHeader from '../ViewHeader.js';
import ViewEditionToolbar from '../ViewEditionToolbar.js';
import LoadingIndicator from '../../common/LoadingIndicator'
import SpreadsheetBody from './SpreadsheetBody'
import LeftSideCell from './LeftSideCell';
import {observer} from 'mobx-react-lite'
import SpreadsheetHeader from './SpreadsheetHeader'
import HeaderSelector from './HeaderSelector'

const Spreadsheet = observer(function Spreadsheet(props) {
    const screen = props.screen
    // const app_context= props.app_context
    const [focusedColumn, setFocusedColumn] = useState(-1)
    const [focusedRow, setFocusedRow] = useState(-1)
    const [columnWidthMap, setColumnWidthMap] = useState({})
    const [selectedIndex, setSelectedIndex] = useState({})
    // const [orderedColumn, setOrderedColumn] = useState({ index: false, order: false })
    const [selectedCells, setSelectedCells] = useState({})
    const gridHeader = useRef(null);
    const gridBody = useRef(null);
    const navigator = useRef(null);
    const height = props.height ? props.height - screen.header_row_height : ((window.innerHeight * 80) / 100) - screen.header_row_height;
    const overscanColumnCount = 0;
    const overscanRowCount = 5;
    const rowHeight = screen.row_height;
    const columnCount = screen.visible_fields.length;
    const rowCount = screen.data.records.length
    const isSpreadsheet = screen.type === 'spreadsheet'
    const container_ref = React.createRef()
    const selected_records = screen.selected_records
    const edition_mode = screen.editor ? screen.editor.design_mode:false
    const [columnMoving, setcolumnMoving] = useState(false)
    


    //list view methods ?
    useEffect(() => {

        let columns = [...screen.visible_fields]
        // if(columns.length){
        let map = { ...columnWidthMap }
        map.sizes = {}

        columns.forEach(function (column, index) {
            let column_width = column.width
            
            if(column_width && typeof(column.width) == 'string' && column_width.includes('%')){
                // use view width -20px (corresponding to left selection checkbox)
                column_width = (width) =>  {
                    if(typeof(column.width) != 'string'){
                        return column.width
                    }
                    return ((width-20)*parseInt(column.width.replace('%','')))/100 }               
            }
            else{
                column_width = parseInt(column_width) || 200
            }
            // map.sizes[index] = parseInt(column.width) || 200;
            map.sizes[index] = column_width
        })
        setColumnWidthMap(map)
        gridHeader.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })
        gridBody.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })
        // }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen.visible_fields, screen.group_by]);



    //END Hooks




    function onResizeStop({ columnIndex, width }) {
        let map = columnWidthMap
        map.column_on_drag = false
        setColumnWidthMap(map)
        if(edition_mode){
            screen.set_columns_changed(map.sizes, width)
        }
    }

    function getColumnWidth({ index, width }) {
        switch (index) {
            case 0: return 20;
            // default: return (width*33)/100
            default: {
                const size = columnWidthMap.sizes[index]
                if(!size){
                    return 200
                }
                else if (typeof size === 'function'){
                    return size(width)
                }
                else{
                    return columnWidthMap.sizes[index]
                }
                
            }
        }
    }

    


    const changeSelection = (e, rowIndex) => {
        
        screen.changeSelection({index:rowIndex})
        

    }


    

    const inSelectedRange = (key) => {
        return selectedCells.hasOwnProperty(key)
    }


    function createKey(rowIndex, columnIndex) {
        return rowIndex.toString().concat('-').concat(columnIndex.toString())
    }

    function selectRange(key, rowIndex, columnIndex) {
        let sc = {}

        let rows_to_select = []
        let columns_to_select = []

        let col_dif = columnIndex - focusedColumn
        let row_dif = rowIndex - focusedRow


        if (col_dif >= 0) {
            let col_to_select = focusedColumn
            while (col_to_select <= columnIndex) {
                columns_to_select.push(col_to_select)
                col_to_select = col_to_select + 1
            }

        }

        if (col_dif < 0) {
            let col_to_select = focusedColumn
            while (col_to_select >= columnIndex) {
                columns_to_select.push(col_to_select)
                col_to_select = col_to_select - 1
            }

        }

        if (row_dif >= 0) {
            let row_to_select = focusedRow
            while (row_to_select <= rowIndex) {
                rows_to_select.push(row_to_select)
                row_to_select = row_to_select + 1
            }
        }

        if (row_dif < 0) {
            let row_to_select = focusedRow
            while (row_to_select >= rowIndex) {
                rows_to_select.push(row_to_select)
                row_to_select = row_to_select - 1
            }
        }

        rows_to_select.forEach(function (row) {
            columns_to_select.forEach(function (col) {
                sc[createKey(row, col)] = true
            })
        })





        setSelectedCells(sc)
    }



    function hoverCell(e, key, rowIndex, columnIndex) {

        if (e.buttons) {
            e.persist()
            selectRange(key, rowIndex, columnIndex)

        }

    }


    //Renderers




    function resizeColumn({ deltaX, columnIndex, lastX, width }) {

        let map = { ...columnWidthMap }

        let current_width = map.sizes[columnIndex]
        // If type function, the current value is a %, calc it just the first time
        if(typeof current_width == 'function'){
            current_width = getColumnWidth({index:columnIndex, width})
        }
        const new_width = Math.max(50, (current_width + (deltaX - lastX)))

        map.sizes[columnIndex] = new_width
        map.column_on_drag = columnIndex

        setColumnWidthMap(map)



        gridHeader.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })
        gridBody.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })




    }
    function moveColumnStart({columnIndex}){
        setcolumnMoving({'idx':columnIndex, 'x':0, 'description':screen.visible_fields[columnIndex].description})
    }
    function moveColumnStop({columnIndex, width}){
        setcolumnMoving(false)
        gridHeader.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })
        gridBody.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })
        screen.set_columns_changed(columnWidthMap.sizes, width, true)

    }

    function moveColumn({ deltaX, columnIndex, lastX, width, event, data }){
        let map = { ...columnWidthMap }
        let columnWidth = getColumnWidth({index:columnIndex, width})
        let upLimit
        let lowLimit
        let to_index = false
        if(columnMoving && columnMoving.x){
            columnIndex = columnMoving.idx
            upLimit = columnMoving.x + getColumnWidth({index:columnIndex+1, width})
            lowLimit = columnMoving.x - getColumnWidth({index:columnIndex-1, width})
        }
        else{
            const next_column_idx = lastX > 0 ? columnIndex+1:columnIndex-1
            upLimit = getColumnWidth({index:next_column_idx, width})
            lowLimit = -(upLimit)
        }
       
        
        
        console.log("START-------")
        console.log("uplimit: " + upLimit)
        console.log("lowLimit: " + lowLimit)
        console.log("lastX: " + lastX)
        console.log("ColumnWidth: " + columnWidth)
        
        if(lastX  > upLimit){
            to_index = columnIndex +1
            if(to_index >= screen.visible_fields.length){
                return
            }
            console.log("SETTING UPPER")
            setcolumnMoving({...columnMoving, 'idx':to_index, 'x':lastX})
            screen.reorder_columns({from:columnIndex, to:to_index})
            
        }
        else if(lastX < lowLimit){
            to_index = columnIndex-1
            if(!to_index){
                return
            }
            console.log("SETTING LOWER")
            setcolumnMoving({...columnMoving, 'idx':to_index, 'x':lastX})
            screen.reorder_columns({from:columnIndex, to:to_index})
            
        }
        console.log("END-------")
        
        gridHeader.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })
        gridBody.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })


        
    }
    // function moveColumn({ deltaX, columnIndex, lastX, width, event, data }){
    //     let map = { ...columnWidthMap }
    //     let upLimit = getColumnWidth({index:columnIndex, width})
    //     let lowLimit = 0
    //     let to_index = false
    //     if(columnMoving && columnMoving.x){
    //         columnIndex = columnMoving.idx
    //         console.log(getColumnWidth({index:columnIndex, width}))
    //         upLimit = columnMoving.x + getColumnWidth({index:columnIndex, width})
    //         lowLimit = columnMoving.x - getColumnWidth({index:columnIndex, width})
    //     }
        
    //     if(lastX > upLimit){
    //         to_index = columnIndex +1
    //         if(to_index >= screen.visible_fields.length){
    //             return
    //         }
    //         setcolumnMoving({...columnMoving, 'idx':to_index, 'x':lastX})
    //         screen.reorder_columns({from:columnIndex, to:to_index})
            
    //     }
    //     else if(lastX<lowLimit){
    //         to_index = columnIndex-1
    //         if(!to_index){
    //             return
    //         }
    //         setcolumnMoving({...columnMoving, 'idx':to_index, 'x':lastX})
    //         screen.reorder_columns({from:columnIndex, to:to_index})
            
    //     }
        
    //     gridHeader.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })
    //     gridBody.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })


        
    // }



    function renderLeftHeaderCell({ columnIndex, key, style }) {


        return (
            <div key={key} style={style}>
                <HeaderSelector screen={screen} />
            </div>
            
        );
    }

    function renderLeftSideCell({ columnIndex, key, rowIndex, style }) {

        
        let rowClass = rowIndex % 2 === 0 ? "TableEvenRow" : "TableOddRow"
        rowClass = clsx("leftCheckbox", rowClass)
        

        return (
            <div  key={key} style={style} >
                <LeftSideCell 
                    className={rowClass}  
                   
                    // style={style} 
                    isSpreadsheet={screen.type == 'spreadsheet'}
                    screen={screen} 
                    rowIndex={rowIndex} 
                    changeSelection={changeSelection}/>
            </div>
        );
    }

    function executeAction(id, params){
        let action = props.getActionById(id)
        //reset selection in case the record is deleted
        if(action.type === 'delete_record'){
            setSelectedIndex({})
        }
        return props.executeAction(id,params)
    }




    return (
        <React.Fragment>
            <LoadingIndicator
                ref={container_ref}
                show={screen.data.loading}
                // show={true}
                // close={()=>{}}
            />
             
            {(!props.headless) &&
                <ViewHeader
                    screen={screen}
                    hide_toolbar={props.hide_toolbar}
                    isSpreadsheet={isSpreadsheet}
                    container_classname={props.height ? false:"rounded bg-white border border-gray-300"}
                    rowCount={rowCount}
                    {...props.header_props}
                />
            }
            <ViewEditionToolbar
                screen={screen}
            />
           


            <ScrollSync>
                {({
                    // clientHeight,
                    // clientWidth,
                    onScroll,
                    // scrollHeight,
                    scrollLeft,
                    scrollTop,
                    // scrollWidth,
                }) => {
                    // const x = scrollLeft / (scrollWidth - clientWidth);
                    // const y = scrollTop / (scrollHeight - clientHeight);

                    return (
                        

                        <Container type={props.container || 'paper'} className="px-2 border overflow-hidden rounded">
                            
                            <div className="GridRow">
                                <div
                                    className="LeftSideGridContainer"
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,

                                    }}>
                                    <Grid
                                        cellRenderer={renderLeftHeaderCell}
                                        className={"HeaderGrid border-none"}
                                        width={20}
                                        height={rowHeight}
                                        rowHeight={rowHeight}
                                        columnWidth={getColumnWidth}
                                        rowCount={1}
                                        columnCount={1}
                                    />

                                </div>

                                <div
                                    className={"LeftSideGridContainer"}
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: screen.header_row_height,

                                    }}>
                                    <Grid
                                        overscanColumnCount={overscanColumnCount}
                                        overscanRowCount={overscanRowCount}
                                        cellRenderer={renderLeftSideCell}
                                        columnWidth={getColumnWidth}
                                        columnCount={1}
                                        className={"LeftSideGrid"}
                                        height={height - scrollbarSize()}
                                        rowHeight={rowHeight}
                                        rowCount={rowCount}
                                        scrollTop={scrollTop}
                                        width={20}
                                    />


                                </div>


                                <div  ref={container_ref} className="GridColumn">
                                    <AutoSizer disableHeight>
                                        {({ width }) => (
                                            <div  >
                                                <div
                                                
                                                    style={{

                                                        height: screen.header_row_height,
                                                        width: width - scrollbarSize(),

                                                    }}>
                                                    
                                                    <SpreadsheetHeader
                                                        screen={screen}
                                                        scrollLeft={scrollLeft}
                                                        rowHeight={screen.header_row_height}
                                                        gridHeaderRef={gridHeader}
                                                        width={width}
                                                        columnWidthMap={columnWidthMap}
                                                        columnWidth={getColumnWidth}
                                                        onResizeStop={onResizeStop}
                                                        resizeColumn={resizeColumn}
                                                        moveColumn={moveColumn}
                                                        moveColumnStart={moveColumnStart}
                                                        moveColumnStop={moveColumnStop}
                                                        columnMoving={columnMoving}
                                                    />
                                                </div>

                                                
                                                    <div
                                                        
                                                        style={{

                                                            color: 'black',

                                                            width,
                                                        }}>




                                                        <SpreadsheetBody
                                                            className="BodyGrid"
                                                            screen={screen}
                                                            columnWidth={getColumnWidth}
                                                            columnCount={columnCount}
                                                            height={height}
                                                            onScroll={onScroll}
                                                            overscanColumnCount={overscanColumnCount}
                                                            // noData={props.noData}
                                                            selectedIndex={selectedIndex}
                                                            overscanRowCount={overscanRowCount}
                                                            // cellRenderer={renderBodyCell}
                                                            rowHeight={rowHeight}
                                                            rowCount={rowCount}
                                                            // ref={gridBody}
                                                            gridRef={gridBody}
                                                            width={width}
                                                            // added
                                                            // columns={props.columns}
                                                            // isSpreadsheet={isSpreadsheet}
                                                            // data={props.data}
                                                            // getLazyValues={props.getLazyValues}
                                                            // dispatch={props.dispatch}
                                                            // orderedColumn={orderedColumn}
                                                            disableAutoSave={props.disableAutoSave}
                                                            getDefaultValues={props.getDefaultValues}
                                                            onDataChange={props.onDataChange}
                                                            prepareValues={props.prepareValues}
                                                            selected_records = {selected_records}
                                                            columnMoving={columnMoving}


                                                        />





                                                    </div>
                                                


                                            </div>
                                        )}
                                    </AutoSizer>
                                    {/* <div className="w-full">Footer Component Here</div> */}
                                </div>
                            </div>
                        </Container>

                    );
                }}
            </ScrollSync>
            
        </React.Fragment>


    )

}
)


export default Spreadsheet;